import cn from "classnames";
import {
  type FormEvent,
  type FormHTMLAttributes,
  type PointerEvent,
  type ReactNode,
  useCallback,
  useRef,
} from "react";
import type { Merge } from "types/commons";
import { Button, type ButtonProps } from "ui-kit/Button";
import { ICONS } from "ui-kit/ICONS";
import { setAbortableTimeout } from "utils";

import styles from "./Dialog.module.scss";
import { modalDialogContext, useDialogContext } from "./context";

const DialogProvider = ({
  id,
  children,
}: {
  id?: string;
  children?: ReactNode;
}) => {
  const dialogRef = useRef<HTMLDialogElement>();

  const cancelDialogEvent = new Event("cancel");

  const openDialog = () => dialogRef.current?.show();

  const openDialogModal = () => dialogRef.current?.showModal();

  const closeDialog = (returnValue?: string) =>
    dialogRef.current?.close(returnValue);

  const cancelDialog = (returnValue?: string) => {
    dialogRef.current?.dispatchEvent(cancelDialogEvent);
    closeDialog(returnValue);
  };

  return (
    <modalDialogContext.Provider
      value={{
        dialogRef,
        formId: id || "dialogForm",
        openDialog,
        openDialogModal,
        closeDialog,
        cancelDialog,
      }}
    >
      {children}
    </modalDialogContext.Provider>
  );
};

DialogProvider.displayName = "DialogProvider";

const Dialog = <T extends boolean>({
  children,
  className,
  transitionDuration,
  initialOpen,
  initialOpenModal,
  position,
  onClose,
  onAfterClose,
  onCancel,
}: {
  children?: ReactNode;
  className?: string;
  transitionDuration?: number; // ms
  initialOpen?: T;
  initialOpenModal?: T extends true ? false : boolean;
  position?: "left" | "top" | "right" | "bottom" | "center";
  onClose?: (e: Event) => void;
  onAfterClose?: (e: Event) => void;
  onCancel?: (e: Event) => void;
}) => {
  const { dialogRef, cancelDialog } = useDialogContext();
  const timeout = transitionDuration ?? 500;

  const handleClickOutside = (e: PointerEvent<HTMLDialogElement>) =>
    e.target === e.currentTarget && cancelDialog();

  const callbackRef = useCallback(
    (node: HTMLDialogElement | null) => {
      if (!node) return;
      const { signal, abort } = new AbortController();

      //Привязываем dialogRef к элементу
      dialogRef.current = node;

      //Открываем сразу модальный диалог, если требуется
      initialOpen ? node.show() : initialOpenModal && node.showModal();

      const handleCloseDialog = (e: Event) => {
        onClose?.(e);

        // Создаем обработчик события onClose, но с таймаутом, пока не закончится анимация
        onAfterClose &&
          setAbortableTimeout(() => onAfterClose(e), timeout, signal);
      };

      onCancel && node.addEventListener("cancel", onCancel, { signal });

      (onClose || onAfterClose) &&
        node.addEventListener("close", handleCloseDialog, { signal });

      // На всякий случай очищаем обработчики и таймаут при размонтировании
      return () => abort();
    },
    [onCancel, onClose, onAfterClose],
  );

  return (
    <dialog
      ref={callbackRef}
      style={{ "--transition-duration": `${timeout}ms` }}
      className={cn(
        styles.dialogContainer,
        styles[`dialog-${position || "center"}`],
        className,
      )}
      onPointerDown={handleClickOutside}
    >
      {children}
    </dialog>
  );
};

Dialog.displayName = "Dialog";

const DialogCloseButton = ({
  className,
  children,
  variant,
  isCancelButton,
  ...props
}: {
  className?: string;
  children?: ReactNode;
  isCancelButton?: boolean;
} & Partial<ButtonProps>) => {
  const { formId, cancelDialog } = useDialogContext();
  return (
    <Button
      {...props}
      className={cn(isCancelButton || styles.closeButton, className)}
      form={formId}
      type="reset"
      variant={variant || "phantom"}
      onClick={() => cancelDialog()}
    >
      {children || <ICONS.Cross />}
    </Button>
  );
};

DialogCloseButton.displayName = "DialogCloseButton";

const DialogContent = ({
  children,
  className,
  preventCloseOnSubmit,
  onSubmit,
  ...props
}: Merge<
  FormHTMLAttributes<HTMLFormElement>,
  {
    preventCloseOnSubmit?: boolean;
    children?: ReactNode;
    onSubmit?: (
      e: FormEvent<HTMLFormElement>,
      close: (returnValue?: string) => void,
    ) => void;
  }
>) => {
  const { formId, closeDialog } = useDialogContext();

  return (
    <form
      id={formId}
      className={cn(styles.dialogForm, className)}
      method="dialog"
      onSubmit={
        (preventCloseOnSubmit || onSubmit) &&
        ((e) => {
          preventCloseOnSubmit && e.preventDefault();
          onSubmit?.(e, closeDialog);
        })
      }
      {...props}
    >
      {children}
    </form>
  );
};

DialogContent.displayName = "DialogContent";

export { DialogProvider, Dialog, DialogContent, DialogCloseButton };
