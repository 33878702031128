import { StepContainer } from "components/MultiStepForm";
import { CreateBookingFormFooter } from "containers/CreateBookingForm/Layout";
import { useCreateBookingFormContext } from "containers/CreateBookingForm/Provider";
import type { StepNumber } from "containers/CreateBookingForm/model";
import dayjs from "dayjs";
import { useFetchWorkdaysQuery } from "features/api/shifts";
import { type FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { DateISO } from "types/commons";

import styles from "./BookingDateStep.module.scss";
import { MonthSelect } from "./MonthSelect";
import { SmallCalendar } from "./SmallCalendar";

const STEP_NUMBER: StepNumber = 1;

export const BookingDateStep = () => {
  const {
    bookingData,
    restaurant,
    updateBookingData,
    updateStepValidationState,
    stepValidationState,
    findNextInvalidStep,
  } = useCreateBookingFormContext();

  const now = dayjs.tz();
  const [currentMonth, setCurrentMonth] = useState(() =>
    dayjs.tz(bookingData.bookingDate).startOf("M"),
  );

  const { data } = useFetchWorkdaysQuery({
    restaurantId: restaurant.id,
    start_date: (now.isAfter(currentMonth) ? now : currentMonth).format(
      "YYYY-MM-DD",
    ),
    end_date: currentMonth.endOf("M").format("YYYY-MM-DD"),
  });

  const workDaysSet = new Set(data);

  const navigate = useNavigate();

  const handleBookingDateChange: FormEventHandler<HTMLFormElement> = (e) => {
    const bookingDate = new FormData(e.currentTarget).get("bookingDate");
    // Если в форме есть выбранная дата
    if (workDaysSet.has(String(bookingDate))) {
      // обновлем значения даты в контексте
      updateBookingData({ bookingDate: String(bookingDate) });

      //обновляем валидацию
      const newValidationState = {
        // Текущий шаг валиден
        step1: true,

        // Второй и четвертый шаг меняют валидацию на undefined, если они был true, так как смена даты требует повторной их валидации
        step2: stepValidationState.step2 && undefined,
        step4: stepValidationState.step4 && undefined,
      };
      updateStepValidationState(newValidationState);
      // Если есть еще не валидные шаги, то редирект пользователя на него
      const nextInvalidStepNumber = findNextInvalidStep(
        STEP_NUMBER,
        newValidationState,
      );
      nextInvalidStepNumber && navigate(`../step-${nextInvalidStepNumber}`);
    }
  };

  return (
    <>
      <StepContainer>
        <form className={styles.form} onChange={handleBookingDateChange}>
          <MonthSelect
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
          />
          <SmallCalendar
            name="bookingDate"
            year={currentMonth.year()}
            month={currentMonth.month()}
            defaultDateISO={
              (bookingData.bookingDate ||
                dayjs.tz().format("YYYY-MM-DD")) as DateISO
            }
            enabledDaysSet={workDaysSet as Set<DateISO>}
          />
        </form>
      </StepContainer>
      {workDaysSet.has(bookingData.bookingDate) && (
        <CreateBookingFormFooter
          currentStep={STEP_NUMBER}
          onBeforeNavigate={() => {
            //обновляем валидацию
            const newValidationState = {
              // Текущий шаг валиден
              step1: true,

              // Второй и четвертый шаг меняют валидацию на undefined, если они был true, так как смена даты требует повторной их валидации
              step2: stepValidationState.step2 && undefined,
              step4: stepValidationState.step4 && undefined,
            };
            updateStepValidationState(newValidationState);
          }}
        />
      )}
    </>
  );
};
