import EachAccessRule from "components/EachAccessRule/EachAccessRule";
import { AccessRulesModal } from "components/shifts/Modal/AccessRulesModal/AccessRulesModal";
import { restaurantSelector } from "features/AppContext";
import { useGetAccessRulesQuery } from "features/api/access_rules";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import { Button, Spinner } from "ui-kit";

import "../settings.scss";

type AccessRulesProps = {
  isLoading: boolean;
};

export default function AccessRules({ isLoading }: AccessRulesProps) {
  const restaurant = useSelector(restaurantSelector);
  const { data: ruleGroups } = useGetAccessRulesQuery(restaurant.restaurant_id);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const modalOnClose = useCallback(() => {
    setModalOpen((a) => !a);
  }, []);
  const handleOpen = () => setModalOpen(() => true);
  const intl = useIntl();

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="access_rules sections include-table">
          <Button
            className="create_rule_button"
            variant="primary"
            onClick={handleOpen}
          >
            {intl.formatMessage({
              id: ETranslations.CREATE_ACCESS_RULES_GROUP,
            })}
          </Button>
          <div className="access_rules_box">
            {ruleGroups?.map((ruleGroup) => (
              <EachAccessRule key={ruleGroup.id} {...ruleGroup} />
            ))}
          </div>
          {modalOpen ? (
            <AccessRulesModal
              isCreating={true}
              isOpen={modalOpen}
              title={intl.formatMessage({
                id: ETranslations.CREATING_ACCESS_RULES_GROUP,
              })}
              onClose={modalOnClose}
            />
          ) : null}
        </div>
      )}
    </>
  );
}
