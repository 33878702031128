import { skipToken } from "@reduxjs/toolkit/query";
import { Modal } from "components/modal";
import AccessRuleForm from "components/shifts/Form/AccessRuleForm/AccessRuleForm";
import { useGetAccessRuleQuery } from "features/api/access_rules";
import type { AccessRuleGroup } from "models/accessRules.model";
import { Spinner } from "ui-kit";

import { BaseShiftsModal } from "../BaseShiftsModal";
import styles from "../style.module.scss";

interface AccessRulesModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  access_rule?: AccessRuleGroup;
  isCreating?: boolean;
  isEditMode?: boolean;
  setIsEditMode?: any;
}

export const AccessRulesModal = ({
  isOpen,
  onClose,
  title,
  access_rule,
  isCreating = false,
  isEditMode,
  setIsEditMode,
}: AccessRulesModalProps) => {
  const { data, isSuccess } = useGetAccessRuleQuery(
    access_rule?.id || skipToken,
  );
  return (
    <BaseShiftsModal isOpen={isOpen} title={title} onClose={onClose}>
      <Modal.Content className={styles.modalContentAccess}>
        {data ? (
          <AccessRuleForm
            key={"isSuccess"}
            access_rule={data}
            isCreating={isCreating}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            onClose={onClose}
          />
        ) : (
          <AccessRuleForm
            key={"Fail"}
            access_rule={access_rule!}
            isCreating={isCreating}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            onClose={onClose}
          />
        )}
      </Modal.Content>
    </BaseShiftsModal>
  );
};
