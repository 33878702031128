import { CurrentRuleModal } from "components/shifts/Modal/AccessRulesModal/CurrentRuleModal";
import dayjs from "dayjs";
import { usAccessRulesActions } from "features/AccessRules/slice";
import { useUserLocale } from "hooks/useUserLocale";
import type { AccessRule, AccessRuleGroup } from "models/accessRules.model";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { EDaysOfWeek } from "types/commons";
import { ETranslations } from "types/translates";
import { Calendar, Edit } from "ui-kit/ICONS/icons";

import styles from "./EachAccessRule.module.scss";

const EachRule = ({
  rule,
  ruleGroup,
}: {
  rule: AccessRule;
  ruleGroup: AccessRuleGroup;
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { userLocale } = useUserLocale();
  const langauge = userLocale?.split("_")[0] || "";

  const intl = useIntl();
  const { setSelectedAccessRule, reset: resetSelectedAR } =
    usAccessRulesActions();
  const modalOnClose = useCallback(() => {
    setSelectedAccessRule(ruleGroup);
    setModalOpen((a) => {
      if (a) {
        resetSelectedAR();
      }
      return !a;
    });
  }, [rule]);

  const startDate = `${dayjs(rule.start_date)
    .locale(langauge)
    .format("MMMM")
    .slice(0, 3)} ${dayjs(rule.start_date).locale(langauge).format("D")}`;
  const endDate =
    dayjs(rule.end_date).valueOf() >= 2208974400000
      ? intl.formatMessage({ id: ETranslations.INFINITY })
      : `${dayjs(rule.end_date)
          .locale(langauge)
          .format("MMMM")
          .slice(0, 3)} ${dayjs(rule.end_date).locale(langauge).format("D")}`;

  return (
    <tr className={styles.row}>
      <td className={styles.name}>
        {rule.rule_name.length > 20
          ? `${rule.rule_name.slice(0, 20)}...`
          : rule.rule_name}
      </td>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Calendar />
          <span>
            {rule.start_active_time} - {rule.end_active_time}
          </span>
        </div>
      </td>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Calendar />
          <span>
            {startDate} - {endDate}
          </span>
        </div>
      </td>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Calendar />
          <span>
            {rule.repeat_days
              .map((each) =>
                dayjs().isoWeekday(Number(EDaysOfWeek[each])).format("dd"),
              )
              .join(", ")}
          </span>
        </div>
      </td>
      <td className={styles.editIcon} onClick={modalOnClose}>
        <Edit />
      </td>

      <CurrentRuleModal
        currentRule={rule}
        ruleGroup={ruleGroup}
        isOpen={modalOpen}
        title={rule.rule_name}
        onClose={modalOnClose}
      />
    </tr>
  );
};

export default EachRule;
