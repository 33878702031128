import cn from "classnames";
import { NoteSubheader } from "components/RestaurantNotes";
import { DashboardHeader, Header } from "components/root/header";
import { config } from "config";
import { ModalContext } from "contexts/ModalContext";
import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Sidebar } from "../../ui-kit";
import styles from "./Layout.module.scss";
import "./Layout.scss";

export function AppLayout() {
  const { closeModals, state } = useContext(ModalContext);

  return (
    <div
      className={cn(styles.container, {
        modal: state.activeModal?.position === "root",
      })}
      onClick={() => closeModals()}
    >
      <Sidebar />
      <NoteSubheader />
      <Outlet />
      {config.CI_COMMIT_SHORT_SHA && (
        <i className={styles.version}>{config.CI_COMMIT_SHORT_SHA}</i>
      )}
    </div>
  );
}

export const RestaurantLayout = () => {
  const { pathname } = useLocation();
  const pageName = pathname.replace(/^\/([\w-]+)\/?.*$/, "$1");
  const isDialogsBooking = /dialogs\/[\w-]+\/[\w]+-booking|edit/.test(pathname);
  return (
    <>
      <Header isDialogsBooking={isDialogsBooking} />
      <main
        className={cn(styles.main, styles[pageName], {
          [styles.booking]: isDialogsBooking,
        })}
        id={pageName}
      >
        <Outlet />
      </main>
    </>
  );
};

export const DashboardLayout = () => {
  return (
    <>
      <DashboardHeader />
      <main className={cn(styles.main, styles.dashboard)}>
        <Outlet />
      </main>
    </>
  );
};
