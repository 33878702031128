import { z } from "zod";

import { RestaurantId } from "./restaurant.model";
import { UserUUID } from "./user.model";

export const NoteId = z.number().brand("NoteId");
export type NoteId = z.infer<typeof NoteId>;

export const NoteRecordId = z.number().brand("NoteRecordId");
export type NoteRecordId = z.infer<typeof NoteRecordId>;

const NoteRecord = z.object({
  note_id: NoteId,
  created_by: UserUUID,
  created_at: z.string().datetime({ local: true }),
  id: NoteRecordId,
  content: z.string(),
});

// FIXME: убрать лишние id, когда бэк определится
export const NoteDTO = z
  .object({
    restaurant_id: RestaurantId,
    note_date: z.string().date(),
    note_records: NoteRecord.array(),
    id: NoteId,
  })
  .optional();
export type NoteDTO = z.infer<typeof NoteDTO>;
