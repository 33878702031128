import type { Option } from "common/components/MultiSelect/multi-select";
import React from "react";
import { FieldRenderProps, useField } from "react-final-form";
import { SelectBasic } from "ui-kit";

export const SelectField: React.FC<FieldRenderProps<string, any>> = ({
  options,
  input,
  meta,
  ...rest
}) => {
  const {
    input: { value },
  } = useField<Option<string> | null>(input.name, { allowNull: true });
  const currentOption = options.find((option: any) => option.value === value);
  return (
    <SelectBasic
      options={options}
      value={currentOption}
      changedMaxSize
      onChange={(option) =>
        input.onChange(
          (option as { value: unknown; label: string } | undefined)?.value,
        )
      }
      {...rest}
      {...meta}
    />
  );
};
