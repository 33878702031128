import type { WidgetConfig } from "models/accessRules.model";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { DataSection } from "ui-kit/DataSection/DataSection";
import { transformLocale } from "utils";

import styles from "./Widget.module.scss";
import CodeBlock from "./components/CodeBlock";

type Props = {
  widget: WidgetConfig;
};

const Widget: FC<Props> = ({ widget }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.widget}>
      <p className={styles.title}>API</p>
      <CodeBlock
        title="Подключение скрипта в секцию head"
        code='<script src="https://stage-webtask.prime.travel/restaurantWidget.js" defer>'
      />
      <CodeBlock
        title="Добавление блока для виджета в секцию body"
        code={`<div id="restaurantWidget" data-showcaseKey="${widget.widgetKey}" data-target="${window.location.origin}"></div>`}
      />
      <DataSection.Root title={formatMessage({ id: ETranslations.PROPERTIES })}>
        {widget.description && (
          <DataSection.Item
            className={styles.widgetInfoItem}
            label={formatMessage({ id: ETranslations.DESCRIPTION })}
          >
            {widget.description}
          </DataSection.Item>
        )}
        <DataSection.Item
          className={styles.widgetInfoItem}
          label={formatMessage({ id: ETranslations.LANGUAGE })}
        >
          {transformLocale(widget.locale)}
        </DataSection.Item>
        <DataSection.Item
          className={styles.widgetInfoItem}
          label={formatMessage({ id: ETranslations.AUTH_TYPE })}
        >
          {formatMessage({
            id:
              widget.widgetAuthorizationType === "PHONE"
                ? ETranslations.PHONE
                : ETranslations.EMAIL,
          })}
        </DataSection.Item>
        <DataSection.Item
          className={styles.widgetInfoItem}
          label={formatMessage({ id: ETranslations.BOOKING_TYPE })}
        >
          {formatMessage({
            id:
              widget.bookingType === "BOOKING"
                ? ETranslations.PLURAL_BOOKING
                : ETranslations.ORDER,
          })}
        </DataSection.Item>
        {widget.contactPhone && (
          <DataSection.Item
            className={styles.widgetInfoItem}
            label={formatMessage({ id: ETranslations.CONTACT_PHONE })}
          >
            {widget.contactPhone}
          </DataSection.Item>
        )}
        {widget.contactEmail && (
          <DataSection.Item
            className={styles.widgetInfoItem}
            label={formatMessage({ id: ETranslations.CONTACT_EMAIL })}
          >
            {widget.contactEmail}
          </DataSection.Item>
        )}
        {widget.agreementUrl && (
          <DataSection.Item
            className={styles.widgetInfoItem}
            label={formatMessage({ id: ETranslations.AGREEMENT_URL })}
          >
            {widget.agreementUrl}
          </DataSection.Item>
        )}
      </DataSection.Root>
    </div>
  );
};

export default Widget;
