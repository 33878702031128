import { Placement } from "@popperjs/core";
import { getFullName, getMaskPhone } from "common/helpers";
import { Popup } from "components/Popup";
import {
  useFetchSourceContactQuery,
  useFetchSourceOrganizationQuery,
  useFetchSourceQuery,
} from "features/api/source-api";
import type {
  OrganizationId,
  SourceContactId,
  SourceId,
} from "models/source.model";
import { ReactElement } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Card, ICONS, LinkButton, Spinner } from "ui-kit";
import { DataSection } from "ui-kit/DataSection/DataSection";

import styles from "./SourceContactInfoPopup.module.scss";

export const ShortSourceContactDetail = ({
  sourceContactId,
}: {
  sourceContactId: SourceContactId;
}) => {
  const { formatMessage } = useIntl();
  const { data: sourceContact, isLoading } =
    useFetchSourceContactQuery(sourceContactId);
  const sourceType =
    sourceContact?.label && sourceContact.label in ETranslations
      ? formatMessage({ id: sourceContact.label })
      : sourceContact?.label;

  if (isLoading || !sourceContact)
    return (
      <div className={styles.info}>
        <Spinner />
      </div>
    );

  return (
    <Card className={styles.info} as="article">
      <Card.Header title={formatMessage({ id: ETranslations.PROFILE })} />
      <Card.Content noPadding className={styles.sourceContact}>
        <img src={ICONS.defaultUser} className={styles.avatar} />
        <strong className={styles.fullName}>
          {getFullName(
            sourceContact?.name,
            sourceContact?.middle_name,
            sourceContact?.surname,
          ) || "N/A"}
        </strong>
        <span className={styles.phone}>
          {sourceContact?.phone ? getMaskPhone(sourceContact.phone) : "N/A"}
        </span>
        <DataSection.Root className={styles.data}>
          <DataSection.Item label={formatMessage({ id: ETranslations.TYPE })}>
            {sourceType || "N/A"}
          </DataSection.Item>
        </DataSection.Root>
        <LinkButton
          className={styles.link}
          variant="phantom"
          to={`/guests/sources/contact/${sourceContact.id}`}
        >
          <ICONS.RightArrow className={styles.icon} />
        </LinkButton>
      </Card.Content>
    </Card>
  );
};

export const ShortSourceOrganizationDetail = ({
  organizationId,
}: {
  organizationId: OrganizationId;
}) => {
  const { formatMessage } = useIntl();
  const { data: sourceOrganization, isLoading } =
    useFetchSourceOrganizationQuery(organizationId);

  if (isLoading || !sourceOrganization)
    return (
      <div className={styles.info}>
        <Spinner />
      </div>
    );

  return (
    <Card className={styles.info} as="article">
      <Card.Header title={formatMessage({ id: ETranslations.PROFILE })} />
      <Card.Content noPadding className={styles.sourceContact}>
        <img src={ICONS.defaultUser} className={styles.avatar} />
        <strong className={styles.fullName}>
          {sourceOrganization.source_name || "N/A"}
        </strong>
        <span className={styles.phone}>
          {sourceOrganization?.phone
            ? getMaskPhone(sourceOrganization.phone)
            : "N/A"}
        </span>
        <DataSection.Root className={styles.data}>
          <DataSection.Item label={formatMessage({ id: ETranslations.TYPE })}>
            {sourceOrganization.organization_type || "N/A"}
          </DataSection.Item>
        </DataSection.Root>
        <LinkButton
          className={styles.link}
          variant="phantom"
          to={`/guests/sources/organization/${sourceOrganization.id}`}
        >
          <ICONS.RightArrow className={styles.icon} />
        </LinkButton>
      </Card.Content>
    </Card>
  );
};

export const ShortSourceDetail = ({ sourceId }: { sourceId: SourceId }) => {
  const { formatMessage } = useIntl();
  const { data: source, isLoading } = useFetchSourceQuery(sourceId);
  const sourceContact = source?.source_contact;
  const sourceType =
    sourceContact?.label && sourceContact.label in ETranslations
      ? formatMessage({ id: sourceContact.label })
      : sourceContact?.label;

  if (isLoading || !source)
    return (
      <div className={styles.info}>
        <Spinner />
      </div>
    );

  return (
    <Card className={styles.info} as="article">
      <Card.Header title={formatMessage({ id: ETranslations.PROFILE })} />
      <Card.Content noPadding className={styles.sourceContact}>
        <img src={ICONS.defaultUser} className={styles.avatar} />
        <strong className={styles.fullName}>
          {getFullName(
            sourceContact?.name,
            sourceContact?.middle_name,
            sourceContact?.surname,
          ) || "N/A"}
        </strong>
        <span className={styles.phone}>
          {sourceContact?.phone ? getMaskPhone(sourceContact.phone) : "N/A"}
        </span>
        <DataSection.Root className={styles.data}>
          <DataSection.Item label={formatMessage({ id: ETranslations.TYPE })}>
            {sourceType || "N/A"}
          </DataSection.Item>
          <DataSection.Item
            label={formatMessage({ id: ETranslations.ORGANIZATION })}
          >
            {source.source_organization?.source_name ||
              formatMessage({ id: ETranslations.WITHOUT_ORGANIZATION })}
          </DataSection.Item>
        </DataSection.Root>
        <LinkButton
          className={styles.link}
          variant="phantom"
          to={`/guests/sources/${source.source_id}`}
        >
          <ICONS.RightArrow className={styles.icon} />
        </LinkButton>
      </Card.Content>
    </Card>
  );
};

export function SourceContactInfoPopup({
  children,
  sourceContactId,
  placement = "auto",
}: {
  sourceContactId: SourceContactId;
  children: ReactElement;
  placement?: Placement;
}) {
  return (
    <Popup placement={placement}>
      <Popup.Trigger>{children}</Popup.Trigger>
      <Popup.Content noPadding>
        <ShortSourceContactDetail sourceContactId={sourceContactId} />
      </Popup.Content>
    </Popup>
  );
}

export function SourceOrganizationInfoPopup({
  children,
  organizationId,
  placement = "auto",
}: {
  organizationId: OrganizationId;
  children: ReactElement;
  placement?: Placement;
}) {
  return (
    <Popup placement={placement}>
      <Popup.Trigger>{children}</Popup.Trigger>
      <Popup.Content noPadding>
        <ShortSourceOrganizationDetail organizationId={organizationId} />
      </Popup.Content>
    </Popup>
  );
}
export function SourceInfoPopup({
  children,
  sourceId,
  placement = "auto",
}: {
  sourceId: SourceId;
  children: ReactElement;
  placement?: Placement;
}) {
  return (
    <Popup placement={placement}>
      <Popup.Trigger>{children}</Popup.Trigger>
      <Popup.Content noPadding>
        <ShortSourceDetail sourceId={sourceId} />
      </Popup.Content>
    </Popup>
  );
}
