import cn from "classnames";
import { getFullName, getMaskPhone } from "common/helpers";
import { InfiniteList } from "components/InfiniteList";
import { useLazyFetchSourcesPageQuery } from "features/api/source-api";
import type {
  OrganizationId,
  SourceContact,
  SourceContactId,
  SourceSearchDTO,
  SourceSearchParams,
} from "models/source.model";
import { forwardRef, memo } from "react";
import { NavLink } from "react-router-dom";
import { Button, Card, ICONS, Tag } from "ui-kit";

import {
  SourceContactInfoPopup,
  SourceOrganizationInfoPopup,
} from "./SourceInfoPopups";
import type { SelectSourceFn } from "./SourceList";
import styles from "./SourceList.module.scss";

const SourceContactItem = memo(
  forwardRef(
    (
      {
        id,
        phone,
        fullName,
        sourceOrganization,
        sourceContactType,
        onClick,
      }: {
        id: SourceContactId;
        phone: string;
        fullName: string;
        sourceOrganization?: string;
        sourceContactType?: SourceContact["label"] | null;
        onClick?: () => void;
      },
      ref: React.Ref<HTMLElement>,
    ) => {
      const Element = onClick ? "button" : NavLink;
      return (
        <article ref={ref} className={styles.source}>
          <Element
            to={`contact/${id}`}
            type="submit"
            //@ts-ignore
            className={
              Element === "button"
                ? styles.clientInfo
                : ({ isActive, isPending }) =>
                    cn(styles.clientInfo, {
                      [styles.active]: isActive,
                      [styles.pending]: isPending,
                    })
            }
            onClick={onClick}
          >
            <h3 className={styles.fullName}>{fullName}</h3>
            <p className={styles.phone}>
              <ICONS.USER_PHONE />
              <span>{phone}</span>
            </p>
            {(sourceOrganization || sourceContactType) && (
              <span>
                {sourceContactType && (
                  <Tag color="#F5F5F5">{sourceContactType}</Tag>
                )}
                {sourceOrganization && (
                  <Tag className={styles.organizationTag} color="#F5F5F5">
                    <ICONS.Building />
                    {sourceOrganization}
                  </Tag>
                )}
              </span>
            )}
          </Element>
          <SourceContactInfoPopup sourceContactId={id} placement="auto">
            <Button
              className={styles.clientDetail}
              type="button"
              variant="phantom"
            >
              <ICONS.Question />
            </Button>
          </SourceContactInfoPopup>
        </article>
      );
    },
  ),
);

const SourceOrganizationItem = memo(
  forwardRef(
    (
      {
        id,
        fullName,
        onClick,
      }: {
        id: OrganizationId;
        fullName: string;
        onClick?: () => void;
      },
      ref: React.Ref<HTMLElement>,
    ) => {
      const Element = onClick ? "button" : NavLink;
      return (
        <article ref={ref} className={styles.source}>
          <Element
            to={`organization/${id}`}
            //@ts-ignore
            className={
              Element === "button"
                ? styles.clientInfo
                : ({ isActive, isPending }) =>
                    cn(styles.clientInfo, {
                      [styles.active]: isActive,
                      [styles.pending]: isPending,
                    })
            }
            onClick={onClick}
          >
            <h3 className={styles.fullName}>{fullName}</h3>
          </Element>
          <SourceOrganizationInfoPopup organizationId={id} placement="auto">
            <Button
              className={styles.clientDetail}
              type="button"
              variant="phantom"
            >
              <ICONS.Question />
            </Button>
          </SourceOrganizationInfoPopup>
        </article>
      );
    },
  ),
);

const SourceInfiniteList = ({
  filter,
  initialSources,
  onSelect,
}: {
  filter: SourceSearchParams & { page?: number; size?: number; sort?: string };
  initialSources: SourceSearchDTO["content"];
  onSelect?: SelectSourceFn;
}) => {
  const [fetch] = useLazyFetchSourcesPageQuery();
  return (
    <Card.Content as="ul" className={styles.sourceList}>
      <InfiniteList
        initialItems={initialSources}
        fetchNextPage={(page) =>
          fetch({
            ...filter,
            page,
          })
        }
      >
        {({ item: source, ref }) => (
          <li key={source.source_id}>
            {source.source_type !== "ORGANIZATION" ? (
              <SourceContactItem
                id={source.source_contact!.id!}
                phone={
                  (source.source_contact?.phone &&
                    getMaskPhone(source.source_contact.phone)) ||
                  ""
                }
                fullName={
                  getFullName(
                    source.source_contact?.surname,
                    source.source_contact?.name,
                    source.source_contact?.middle_name,
                  ) || "N/A"
                }
                sourceContactType={source.source_contact?.label}
                sourceOrganization={source.source_organization?.source_name}
                ref={ref}
                onClick={
                  onSelect &&
                  (() =>
                    onSelect({
                      id: source.source_id,
                      fullName:
                        getFullName(
                          source.source_contact?.surname,
                          source.source_contact?.name,
                          source.source_contact?.middle_name,
                        ) || "N/A",
                      phone:
                        (source.source_contact?.phone &&
                          getMaskPhone(source.source_contact.phone)) ||
                        undefined,
                    }))
                }
              />
            ) : (
              <SourceOrganizationItem
                id={source.source_organization.id}
                fullName={source.source_organization.source_name || "N/A"}
                ref={ref}
                onClick={
                  onSelect &&
                  (() =>
                    onSelect({
                      id: source.source_id,
                      fullName: source.source_organization.source_name || "N/A",
                      phone:
                        (source.source_organization?.phone &&
                          getMaskPhone(source.source_organization.phone)) ||
                        undefined,
                    }))
                }
              />
            )}
          </li>
        )}
      </InfiniteList>
    </Card.Content>
  );
};

SourceInfiniteList.displayName = "SourceInfiniteList";

export { SourceInfiniteList };
