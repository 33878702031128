import type { ConfirmDialogProps } from "components/ConfirmModal";
import { FormFooter } from "components/MultiStepForm";
import { config } from "config";
import {
  useCreateNoteMutation,
  useFetchRestaurantNotesQuery,
} from "features/api/notes-api";
import type { NoteId, NoteRecordId } from "models/restaurant-notes.model";
import type { RestaurantId } from "models/restaurant.model";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import {
  Button,
  Card,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogProvider,
  ICONS,
  Spinner,
  TextareaComposite,
} from "ui-kit";

import { ETranslations } from "../../../types/translates";
import { Note } from "./Note";
import styles from "./NoteDialog.module.scss";
import { NoteForm } from "./NoteForm";

type Tab = "NOTES" | "PROGRAMS";

export const NotesDialog = ({
  restaurantId,
  date,
  showConfirmDialog,
  onAfterClose,
}: {
  restaurantId: RestaurantId;
  date: string;
  showConfirmDialog: (params: ConfirmDialogProps | null) => void;
  onAfterClose: () => void;
}) => {
  const [activeTab, setActiveTab] = useState<Tab>("NOTES");
  const [noteFormIntent, setNoteFormIntent] = useState<"create" | "edit">();
  const { data, isLoading } = useFetchRestaurantNotesQuery({
    restaurantId,
    date,
  });
  const { formatMessage } = useIntl();
  const noteInfo = data?.note_records.length
    ? {
        noteId: data?.id,
        noteRecordId: data.note_records[0].id,
        noteText: data.note_records[0].content,
      }
    : undefined;

  return (
    <DialogProvider>
      <Dialog initialOpenModal onAfterClose={onAfterClose} className={styles.dialog}>
        <DialogContent className={styles.dialogContent} preventCloseOnSubmit>
          <Card className={styles.card} as="section">
            <DialogCloseButton className={styles.closeButton} />
            {noteFormIntent ? (
              <NoteForm
                restaurantId={restaurantId}
                date={date}
                intent={noteFormIntent}
                noteInfo={noteInfo}
                showConfirmDialog={showConfirmDialog}
                onCancel={() => setNoteFormIntent(undefined)}
              />
            ) : (
              <Card.HeaderTabs<Tab>
                className={styles.tabs}
                activeTab={activeTab}
                onChange={setActiveTab}
              >
                <Card.HeaderTabs.Item<Tab>
                  tabName={"NOTES"}
                  title={formatMessage({ id: ETranslations.NOTES })}
                >
                  <Card.Content noPadding>
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <Note
                        noteInfo={noteInfo}
                        setNoteFormIntent={setNoteFormIntent}
                      />
                    )}
                  </Card.Content>
                </Card.HeaderTabs.Item>
                <Card.HeaderTabs.Item<Tab>
                  tabName={"PROGRAMS"}
                  disabled
                  title={formatMessage({ id: ETranslations.PROGRAMS })}
                >
                  {null}
                </Card.HeaderTabs.Item>
              </Card.HeaderTabs>
            )}
          </Card>
        </DialogContent>
      </Dialog>
    </DialogProvider>
  );
};
