import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query";
import type { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import type {
  ClientNoteDTO,
  ClientNoteRequest,
  ClientNotesParams,
} from "models/notes.model";
import { NoteDTO } from "models/restaurant-notes.model";
import type { RestaurantId } from "models/restaurant.model";
import { Notification } from "services/notification";
import type { ErrorResponse } from "types/commons";

import { IResponse } from "../../models/common";
import { ETranslations } from "../../types/translates";
import {
  NoteId,
  type NoteRecordId,
} from "./../../models/restaurant-notes.model";
import { api } from "./api";

export const notesApi = api
  .enhanceEndpoints({
    addTagTypes: ["RestaurantNote", "RestaurantNotes"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchRestaurantNotes: build.query<
        NoteDTO,
        { restaurantId: RestaurantId; date: string }
      >({
        query: ({ restaurantId, date }) => ({
          url: `v2/notes/${restaurantId}/${date}`,
        }),
        transformResponse: (response: IResponse<NoteDTO>) => {
          const result = NoteDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: (result, __, args) => [
          {
            type: "RestaurantNote",
            id: result?.id ?? `${args.restaurantId}-${args.date}`,
          },
        ],
      }),
      createNote: build.mutation<
        NoteDTO,
        { restaurantId: RestaurantId; date: string; noteText: string }
      >({
        query: ({ restaurantId, date, noteText }) => ({
          url: "v2/notes",
          method: "POST",
          body: {
            restaurant_id: restaurantId,
            note_date: date,
            note_records: [
              {
                content: noteText,
              },
            ],
          },
        }),
        transformResponse: (response: IResponse<NoteDTO>) => {
          const result = NoteDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        invalidatesTags: (result, _, args) => [
          {
            type: "RestaurantNote",
            id: result?.id ?? 0,
          },
          {
            type: "RestaurantNote",
            id: `${args.restaurantId}-${args.date}`,
          },
        ],
      }),
      updateNote: build.mutation<
        IResponse<NoteDTO>,
        { noteId: NoteId; noteRecordId: NoteRecordId; noteText: string }
      >({
        query: ({ noteId, noteRecordId, noteText }) => ({
          url: `v2/notes/${noteId}`,
          method: "PUT",
          body: { id: noteRecordId, content: noteText },
        }),
        invalidatesTags: (_, __, args) => [
          {
            type: "RestaurantNote",
            id: args.noteId,
          },
        ],
      }),
      deleteNote: build.mutation<
        IResponse<NoteDTO>,
        { noteId: NoteId; noteRecordId: NoteRecordId }
      >({
        query: ({ noteId, noteRecordId }) => ({
          url: `v2/notes/${noteId}`,
          method: "DELETE",
          body: { id: noteRecordId },
        }),
        invalidatesTags: (_, __, args) => [
          {
            type: "RestaurantNote",
            id: args.noteId,
          },
        ],
      }),
    }),
  });

export const {
  useFetchRestaurantNotesQuery,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} = notesApi;
