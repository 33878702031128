import { DAYS, ELocales } from "types/commons";
import { z } from "zod";

import { PlaceId, RestaurantId } from "./restaurant.model";
import { ShiftId } from "./shift.model";

export const AccessRuleGroupId = z.number().int().brand("AccessRuleGroupId");
export type AccessRuleGroupId = z.infer<typeof AccessRuleGroupId>;

export const WidgetId = z.number().int().brand("WidgetId");
export type WidgetId = z.infer<typeof WidgetId>;

export const AccessRule = z.object({
  places: PlaceId.array(),
  active: z.boolean(),
  access_group_id: AccessRuleGroupId,
  shift_id: ShiftId,
  rule_name: z.string(),
  start_date: z.string().date(),
  end_date: z.string().date(),
  start_active_time: z.string().time(),
  end_active_time: z.string().time(),
  repeat_days: z.enum(DAYS).array(),
});
export type AccessRule = z.infer<typeof AccessRule>;

export const WidgetConfig = z.object({
  widgetKey: z.string().uuid(),
  description: z.string(),
  active: z.boolean(),
  locale: z.nativeEnum(ELocales),
  bookingType: z.enum(["BOOKING_ORDER", "BOOKING"]),
  widgetAuthorizationType: z.enum(["PHONE", "EMAIL"]),
  agreementUrl: z.string().url().optional(),
  contactPhone: z.string().optional(),
  contactEmail: z.string().email().optional(),
  id: WidgetId,
  accessGroupId: AccessRuleGroupId,
});
export type WidgetConfig = z.infer<typeof WidgetConfig>;

export const AccessRuleGroup = z.object({
  id: AccessRuleGroupId,
  name: z.string(),
  restaurant_id: RestaurantId,
  access_rules: AccessRule.array(),
  use_max_covered: z.boolean(),
  max_covered_size: z.number().optional(),
  party_min_person: z.number().optional(),
  party_max_person: z.number().optional(),
});
export type AccessRuleGroup = z.infer<typeof AccessRuleGroup>;

export type AccessRuleGroupCreationPayload = Omit<
  AccessRuleGroup,
  "id" | "access_rules" | "widget"
> & {
  access_rules: Omit<AccessRule, "access_group_id">[];
  widget_booking_type: WidgetConfig["bookingType"];
  widget_locale: WidgetConfig["locale"];
  widget_authorization_typ: WidgetConfig["widgetAuthorizationType"];
  widget_contact_email: WidgetConfig["contactEmail"];
  widget_contact_phone: WidgetConfig["contactPhone"];
  agreement_url: WidgetConfig["agreementUrl"];
};
