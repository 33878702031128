import { OrganizationInput } from "components/SourceInput";
import { type OrganizationId } from "models/source.model";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { type Value as E164Number } from "react-phone-number-input";
import { ETranslations } from "types/translates";
import { Button, Input } from "ui-kit";
import { TrashBin } from "ui-kit/ICONS/icons";

import type {
  SourceFormSchema,
  SourceFormSchemaInput,
} from "../../model/schema";
import styles from "./SourceForm.module.scss";

export const SourceOrganizationFields = () => {
  const [existedOrganization, setExistedOrganization] = useState<{
    id: OrganizationId;
    name: string;
  }>();
  const { formatMessage } = useIntl();
  const { register, control, formState, setValue } = useFormContext<
    SourceFormSchemaInput,
    SourceFormSchema
  >();

  return (
    <details className={styles.details}>
      <summary>{formatMessage({ id: ETranslations.ORGANIZATION })}</summary>
      <input
        type="hidden"
        {...register("organizationId", { required: false })}
      />
      <Controller
        control={control}
        name="organizationId"
        render={({ field: { onChange, value } }) => (
          <>
            <span className={styles.contactSearchBox}>
              <OrganizationInput
                placeholder={formatMessage({
                  id: ETranslations.ORGANIZATION_SEARCH,
                })}
                organizationId={
                 value !== "" ? (Number(value) as OrganizationId) : undefined
                }
                onSelect={(organization) => {
                  if (!organization) {
                    onChange("");
                  } else {
                    onChange(organization.id.toString());

                    setExistedOrganization({
                      id: organization.id,
                      name: organization.source_name,
                    });
                  }
                }}
                onReset={() => {
                  setExistedOrganization(undefined);
                  onChange("");
                }}
              />
              <Button
                className={styles.addNewButton}
                onClick={() => {
                  onChange("");
                  setExistedOrganization(undefined);
                }}
                variant="thin"
              >
                +&nbsp;
                {formatMessage(
                  { id: ETranslations.BASE_ADD_ENTITY },
                  {
                    entity: formatMessage(
                      { id: ETranslations.NEW_UNIT_ALT },
                      { unit: "" },
                    ),
                  },
                )}
              </Button>
            </span>
            {existedOrganization && (
              <span className={styles.selected}>
                <span>{existedOrganization.name || "N/A"}</span>
                <button
                  className={styles.resetButton}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setExistedOrganization(undefined);
                    onChange("");
                  }}
                >
                  <TrashBin />
                </button>
              </span>
            )}
          </>
        )}
      />

      {!existedOrganization && (
        <fieldset className={styles.newContactFields}>
          <Input
            label={formatMessage({ id: ETranslations.SHIFTS_NAME })}
            autoComplete="off"
            required
            {...register("organizationName", { required: false })}
            error={formState.errors.organizationName?.message}
          />
          <Controller
            control={control}
            name="organizationPhone"
            render={({ field: { onChange, value } }) => (
              <Input.Phone
                label={formatMessage({ id: ETranslations.PHONE })}
                autoComplete="off"
                value={value as E164Number}
                onChange={(v) => onChange(v || "")}
                error={formState.errors.organizationPhone?.message}
              />
            )}
          />
          <Input
            label={formatMessage({ id: ETranslations.EMAIL })}
            type="email"
            autoComplete="off"
            {...register("organizationEmail", { required: false })}
            error={formState.errors.organizationEmail?.message}
          />
          <Input
            label={formatMessage({ id: ETranslations.ADDRESS })}
            autoComplete="off"
            {...register("organizationAddress")}
            error={formState.errors.organizationAddress?.message}
          />
        </fieldset>
      )}
    </details>
  );
};
