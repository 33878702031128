import cn from "classnames";
import en_EN from "date-fns/locale/en-GB";
import ru_RU from "date-fns/locale/ru";
import { useUserLocale } from "hooks/useUserLocale";
import { FC, type InputHTMLAttributes } from "react";
import DayPicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import type { Merge } from "types/commons";



import styles from "./DatePicker.module.scss";
import { datePickerHeader } from "./DatePickerHeader";
import { DatePickerInput } from "./DatePickerInput";
import "./customStyles.scss";


registerLocale("ru_RU", ru_RU);
registerLocale("en_EN", en_EN);

const DATE_PICKER_LOCALES = {
  en_EN,
  ru_RU,
};

interface Props extends ReactDatePickerProps {
  minYear?: number;
  customInputFormatDate?: string;
  customInputClassName?: string;
  invalid?: boolean;
  label?: string;
  resetClick?: () => void;
}

export const DatePicker: FC<
  Merge<InputHTMLAttributes<HTMLInputElement>, Props>
> = ({
  onChange,
  selected,
  minYear = 2020,
  customInputFormatDate = "DD.MM.YYYY",
  className,
  disabled,
  customInputClassName,
  invalid = false,
  name,
  label,
  readOnly,
  required,
  ...rest
}) => {
  const { userLocale } = useUserLocale();
  return (
    <div className={className}>
      <DayPicker
        name={name}
        className={cn(styles.datePicker)}
        customInput={
          <DatePickerInput
            label={label}
            name={name}
            customInputClassName={cn(
              customInputClassName,
              cn({ [styles.invalid]: invalid }),
            )}
            disabled={disabled}
            format={customInputFormatDate}
            placeholderText={rest.placeholderText}
            readOnly={readOnly}
            required={required}
          />
        }
        dateFormat="dd.MM.yyyy"
        disabled={disabled}
        locale={DATE_PICKER_LOCALES[userLocale || "en_EN"]}
        renderCustomHeader={(headerProps) =>
          datePickerHeader({ ...headerProps, locale: userLocale }, minYear)
        }
        selected={selected}
        onChange={onChange}
        required={required}
        {...rest}
      />
    </div>
  );
};