import { DefaultErrorBoundary } from "components/ErrorBoundary";
import { useAppUsers } from "contexts/AppUsersContext";
import { restaurantSelector } from "features/AppContext";
import {
  useFetchClientNotesQuery,
  useUpdateNoteMutation,
} from "features/api/client-notes-api";
import { useIntlUtils } from "hooks/useIntlUtils";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import type { LoaderFunction } from "react-router-dom";
import { redirect } from "react-router-dom";
import {
  getPermissionChecker,
  useCheckPermission,
} from "services/permissionChecker";
import { loadStore } from "storage";
import type { Params } from "types/commons";
import { ETranslations } from "types/translates";
import { BottomBar, Button, Spinner } from "ui-kit";

import styles from "./GuestNotes.module.scss";
import { NoteList } from "./NoteList";
import { NoteModal } from "./NoteModal";
import { NoteModalSettings, defaultNoteModalSettings } from "./utils";

export const guestNotesLoader = (async () => {
  const store = await loadStore();
  const { hasPermissionFor } = await getPermissionChecker(store);

  if (!hasPermissionFor("SECTION_GUEST_NOTES", true)) {
    return redirect("/guests");
  }
  return null;
}) satisfies LoaderFunction;

export const GuestNotes = () => {
  const { clientId } = useParams<Params>();
  const { hasPermissionFor } = useCheckPermission();
  const { restaurant_id } = useSelector(restaurantSelector);
  const { currentUser } = useAppUsers();
  const { data, isLoading } = useFetchClientNotesQuery({
    client_id: clientId!,
    restaurant_id,
  });
  const { createNote, editNote } = useUpdateNoteMutation();
  const {
    intl,
    getIntlCreatingOf,
    getIntlEntityCreation,
    getIntlEntityEdition,
  } = useIntlUtils();
  const [modalSettings, setModalSettings] = useState<NoteModalSettings>(
    () => defaultNoteModalSettings,
  );

  if (!data) return <Spinner />;

  const renderModal = () => {
    switch (modalSettings.mode) {
      case "create":
        return (
          <NoteModal
            close={() => setModalSettings(defaultNoteModalSettings)}
            disabled={isLoading}
            formatMessage={intl.formatMessage}
            isOpen={Boolean(modalSettings.mode)}
            key={modalSettings.mode}
            submit={(note: string) =>
              createNote({
                client_id: Number(clientId),
                restaurant_id,
                note,
              })
            }
            submitButtonText={intl.formatMessage({
              id: ETranslations.BASE_CREATE,
            })}
            title={getIntlEntityCreation(ETranslations.A_NOTE)}
          />
        );
      case "edit":
        return (
          <NoteModal
            close={() => setModalSettings(defaultNoteModalSettings)}
            defaultNoteText={modalSettings.note}
            disabled={isLoading}
            formatMessage={intl.formatMessage}
            isOpen={Boolean(modalSettings.mode)}
            key={modalSettings.mode}
            submit={(note: string) =>
              editNote({
                client_id: Number(clientId),
                restaurant_id,
                id: modalSettings.noteId,
                note,
              })
            }
            submitButtonText={intl.formatMessage({
              id: ETranslations.BASE_SAVE,
            })}
            title={getIntlEntityEdition(ETranslations.A_NOTE)}
          />
        );
      case "delete":
        return (
          <NoteModal
            close={() => setModalSettings(defaultNoteModalSettings)}
            disabled={isLoading}
            formatMessage={intl.formatMessage}
            isOpen={Boolean(modalSettings.mode)}
            key={modalSettings.mode}
            submit={() =>
              editNote({
                client_id: Number(clientId),
                restaurant_id,
                id: modalSettings.noteId,
                note: "test—delete",
              })
            }
            submitButtonText={intl.formatMessage({
              id: ETranslations.BASE_SAVE,
            })}
            title={getIntlEntityEdition(ETranslations.A_NOTE)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {data.length ? (
        <NoteList
          formatMessage={intl.formatMessage}
          notes={data}
          userId={currentUser?.user_profile.id}
          onEdit={(noteId: number, note: string) =>
            setModalSettings({ mode: "edit", noteId, note })
          }
        >
          <BottomBar>
            <BottomBar.Part placement="left">
              <Button
                variant="primary"
                disabled={!hasPermissionFor("ACCESS_GUEST_NOTES_LOCAL_CREATE")}
                onClick={() => setModalSettings({ mode: "create" })}
              >
                {getIntlCreatingOf(ETranslations.NOTE_ALT)}
              </Button>
            </BottomBar.Part>
          </BottomBar>
        </NoteList>
      ) : (
        <div className={styles.emptyNotes}>
          {intl.formatMessage({ id: ETranslations.EMPTY_NOTES })}
          <Button
            variant="primary"
            disabled={!hasPermissionFor("ACCESS_GUEST_NOTES_LOCAL_CREATE")}
            onClick={() => setModalSettings({ mode: "create" })}
          >
            {intl.formatMessage({ id: ETranslations.BASE_CREATE })}
          </Button>
        </div>
      )}
      {renderModal()}
    </>
  );
};

export const GuestNotesError = () => (
  <DefaultErrorBoundary entity={ETranslations.NOTES}>
    {ETranslations.ERROR_ENTITY}
  </DefaultErrorBoundary>
);
