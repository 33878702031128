import { getFullName, getMaskPhone } from "common/helpers";
import type { Organization, SourceContact } from "models/source.model";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { ETranslations } from "types/translates";
import { DataSection } from "ui-kit/DataSection/DataSection";

import styles from "./SourceInfo.module.scss";

const SourceContactInfo = () => {
  const sourceContact = useOutletContext<SourceContact>();
  const { formatMessage } = useIntl();
  return (
    <article className={styles.info}>
      <header className={styles.header}>
        <strong className={styles.fullName}>
          {getFullName(
            sourceContact?.surname,
            sourceContact?.name,
            sourceContact?.middle_name,
          ) || "N/A"}
        </strong>
{/*         <LinkButton variant="secondary" to="../edit">
          <ICONS.Edit />
        </LinkButton> */}
      </header>
      <DataSection.Root
        title={formatMessage({ id: ETranslations.CONTACT })}
        className={styles.dataSection}
        dataListClassName={styles.dataList}
      >
        <DataSection.Item label={formatMessage({ id: ETranslations.PHONE })}>
          {sourceContact?.phone ? getMaskPhone(sourceContact.phone) : "N/A"}
        </DataSection.Item>
        <DataSection.Item label={formatMessage({ id: ETranslations.EMAIL })}>
          {sourceContact?.email || "N/A"}
        </DataSection.Item>
        <DataSection.Item label={formatMessage({ id: ETranslations.TYPE })}>
          {sourceContact?.label
            ? formatMessage({ id: sourceContact.label })
            : "N/A"}
        </DataSection.Item>
        {/*  <DataSection.Item
          label={formatMessage({ id: ETranslations.ORGANIZATION })}
        >
          {sourceContact||
            formatMessage({ id: ETranslations.WITHOUT_ORGANIZATION })}
        </DataSection.Item> */}
      </DataSection.Root>
    </article>
  );
};

const SourceOrganizationInfo = () => {
  const organization = useOutletContext<Organization>();
  const { formatMessage } = useIntl();
  return (
    <article className={styles.info}>
      <header className={styles.header}>
        <strong className={styles.fullName}>
          {organization.source_name || "N/A"}
        </strong>
       {/*  <LinkButton variant="secondary" to="../edit">
          <ICONS.Edit />
        </LinkButton> */}
      </header>
      <DataSection.Root
        title={formatMessage({ id: ETranslations.CONTACT })}
        className={styles.dataSection}
        dataListClassName={styles.dataList}
      >
        <DataSection.Item label={formatMessage({ id: ETranslations.PHONE })}>
          {organization?.phone ? getMaskPhone(organization.phone) : "N/A"}
        </DataSection.Item>
        <DataSection.Item label={formatMessage({ id: ETranslations.ADDRESS })}>
          {organization?.address || "N/A"}
        </DataSection.Item>
      </DataSection.Root>
    </article>
  );
};

SourceContactInfo.displayName = "SourceContactInfo";
SourceOrganizationInfo.displayName = "SourceOrganizationInfo";

export { SourceContactInfo, SourceOrganizationInfo };
