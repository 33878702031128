import cn from "classnames";
import { getFullName, getMaskPhone } from "common/helpers";
import { ExternalSourceList } from "containers/Guests/Sources/ui/SourceList/ExternalSourceList";
import { type SelectSourceFn } from "containers/Guests/Sources/ui/SourceList/SourceList";
import { type ButtonHTMLAttributes, useState } from "react";
import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogProvider,
} from "ui-kit";
import { TrashBin, USER_PHONE } from "ui-kit/ICONS/icons";
import { TabItem } from "ui-kit/Tabs/TabItem";

import styles from "./SourceInput.module.scss";

export const SourceInput = ({
  phone,
  fullName,
  placeholder,
  className,
  onSelect,
  onReset,
  ...props
}: {
  phone?: string;
  fullName?: string;
  placeholder?: string;
  className?: string;
  onSelect: SelectSourceFn;
  onReset?: () => void;
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const [isOpen, setIsOpen] = useState(false);
  const isPlaceholder = !phone && !fullName;

  return (
    <div className={cn(styles.sourceButtons, className)}>
      <Button
        className={cn(
          styles.chooseButton,
          isPlaceholder && styles.withPlaceholder,
        )}
        variant="thin"
        onClick={() => setIsOpen(true)}
        {...props}
      >
        {isPlaceholder ? (
          placeholder
        ) : (
          <>
            <span>
              <USER_PHONE />
              {phone || "N/A"}
            </span>
            <span>{fullName || "N/A"}</span>
          </>
        )}
      </Button>
      {!isPlaceholder && (
        <button
          disabled={props.disabled}
          className={styles.resetButton}
          type="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onReset?.();
          }}
        >
          <TrashBin />
        </button>
      )}
      {isOpen && (
        <DialogProvider>
          <Dialog
            transitionDuration={0}
            initialOpen
            onAfterClose={() => setIsOpen(false)}
            className={styles.dialog}
          >
            <DialogContent className={styles.dialogContent}>
              <ExternalSourceList onSelect={onSelect} />
            </DialogContent>
          </Dialog>
        </DialogProvider>
      )}
    </div>
  );
};
