import type { QueryDefinition } from "@reduxjs/toolkit/dist/query";
import type { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import cn from "classnames";
import { type RefObject, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import type { PageableResponse } from "types/response";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";

import styles from "./InfiniteList.module.scss";

const InfiniteList = <
  T extends {},
  D extends QueryDefinition<any, any, any, PageableResponse<T>>,
  R extends HTMLElement = HTMLElement,
>({
  initialItems,
  buttonClassName,
  isLastPage,
  fetchNextPage,
  children,
}: {
  initialItems: T[];
  buttonClassName?: string;
  isLastPage?: boolean;
  fetchNextPage: (page: number) => QueryActionCreatorResult<D>;
  children: ({
    item,
    index,
    arr,
    ref,
  }: {
    item: T;
    index: number;
    arr: T[];
    ref?: RefObject<R>;
  }) => JSX.Element | false;
}) => {
  const { formatMessage } = useIntl();
  const [items, setItems] = useState(initialItems);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchBlocked, setIsFetchBlocked] = useState(false);
  const [nextPage, setNextPage] = useState<number | undefined>(
    isLastPage ? undefined : 1,
  );
  const observerRef = useRef<R>(null);
  const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const observerElement = observerRef.current;
    const loadMoreButton = loadMoreButtonRef.current;
    if (!observerElement) return;

    const observer = new IntersectionObserver((entries) => {
      if (
        (entries[0].isIntersecting || entries[1]?.isIntersecting) &&
        !isLoading &&
        nextPage &&
        !isFetchBlocked
      ) {
        setIsLoading(true);
        fetchNextPage(nextPage).then((data) => {
          setIsLoading(false);
          if (data.data) {
            setNextPage(data.data.last ? undefined : data.data.number + 1);
            data.data.content.length &&
              setItems((prevItems) => prevItems.concat(data.data!.content));
          } else {
            setIsFetchBlocked(true);
          }
        });
      }
    });

    observer.observe(observerElement);
    loadMoreButton && observer.observe(loadMoreButton);

    return () => observer.disconnect();
  }, [
    items,
    nextPage,
    isLoading,
    observerRef.current,
    loadMoreButtonRef.current,
  ]);

  return (
    <>
      {items.map((item, index, arr) =>
        children({
          item,
          index,
          arr,
          ref:
            index === Math.floor(Math.max(items.length / 2, items.length - 20))
              ? observerRef
              : undefined,
        }),
      )}
      {items.length >= 20 && (
        <Button
          variant="secondary"
          className={cn(styles.loadMoreButton, buttonClassName)}
          ref={loadMoreButtonRef}
          disabled={!nextPage}
          onClick={() => {
            if (!isLoading && nextPage) {
              setIsLoading(true);
              fetchNextPage(nextPage).then((data) => {
                setIsLoading(false);
                if (data.data) {
                  setNextPage(
                    data.data.last ? undefined : data.data.number + 1,
                  );
                  setItems((prevItems) => prevItems.concat(data.data!.content));
                  isFetchBlocked && setIsFetchBlocked(false);
                }
              });
            }
          }}
        >
          {formatMessage({
            id: isLoading
              ? ETranslations.LOADING
              : nextPage
                ? ETranslations.LOAD_MORE
                : ETranslations.NOTHING_TO_LOAD,
          })}
        </Button>
      )}
    </>
  );
};

InfiniteList.displayName = "InfiniteList";
export { InfiniteList };
