import { z } from "zod";

import { Client } from "./client.model";
import { Place, PlaceId, RestaurantId } from "./restaurant.model";

export const OrderId = z.number().int().brand("OrderId");
export type OrderId = z.infer<typeof OrderId>;

export const BookingOrderDTO = z.object({
  orderId: OrderId,
  version: z.number().optional(),
  restaurantId: RestaurantId,
  bookingDate: z.string().date(),
  bookingTime: z.string().time(),
  visitTime: z.number(),
  persons: z.number().optional(),
  comment: z.string().optional(),
  useDeposit: z.boolean().optional(),
  source: z.unknown(),
  client: Client,
  places: z.object({
    id: z.number(),
    placeId: PlaceId,
    number: z.string(),
    type: z.unknown(),
    fetcherId: z.number(),
    schemaTable: z.unknown(),
    place: Place,
    min_capacity: z.number().nullable(),
    max_capacity: z.number().nullable(),
  }),
});
export type BookingOrderDTO = z.infer<typeof BookingOrderDTO>;
