import { skipToken } from "@reduxjs/toolkit/query";
import cn from "classnames";
import AccessRuleInfo from "components/AccessRuleInfo/AccessRuleInfo";
import { CancelBooking } from "components/ManagerialTables/form/CancelBooking";
import ModalPopup from "components/shifts/Modal/AccessRulesModal/ModalPopup";
import { config } from "config";
import type { AccessRuleGroup } from "models/accessRules.model";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { ETranslations } from "types/translates";
import { BottomBar, Button, Card, ICONS } from "ui-kit";



import { useGetAccessRuleQuery } from "../../../../features/api/access_rules";
import styles from "./Form.module.scss";
import Capicacity from "./Steps/Capicacity";
import Settings from "./Steps/Settings";
import Widget from "./Steps/Widget/Widget";
import useAccessRuleForm from "./useAccessRuleForm";


type Props = {
  onClose: () => void;
  access_rule: AccessRuleGroup;
  setIsEditMode: any;
  isEditMode?: boolean;
  isCreating?: boolean;
};
interface Content {
  [key: string]: React.ComponentType<any>;
}

interface MyFormValues {
  settings: {
    rules: string[];
  };
}

const Content: Content = {
  settings: Settings,
  capicacity: Capicacity,
  widget: Widget,
};

const AccessRuleForm: FC<Props> = ({
  onClose,
  access_rule,
  setIsEditMode,
  isCreating = false,
  isEditMode,
}) => {
  const {
    initialValue,
    onSubmit,
    activeTab,
    intl,
    onToggleEdit,
    handleDeleteAR,
    openDeleteModal,
    toggleOpenDeleteModal,
    toggleOpensSave,
    openEditModal,
    responseError,
    handleRemoveResponseError,
  } = useAccessRuleForm(access_rule, setIsEditMode, onClose);

  const Component = Content[activeTab];
  const { data, isSuccess } = useGetAccessRuleQuery(
    access_rule?.id || skipToken,
  );

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};
    if (
      !values.settings ||
      !values.settings.rules ||
      values.settings.rules.length === 0
    ) {
      // @ts-ignore
      errors.settings = { rules: "Rules are required" };
    }
    return errors;
  };

  return (
    <Form initialValues={initialValue} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit, valid }) => {
        const handleSubmitForm = () => {
          handleSubmit();
          // form.reset();
        };

        const handleClickSave = () => {
          if (isCreating) {
            handleSubmitForm();
          } else {
            toggleOpensSave();
          }
        };
        return (
          <Card onClose={close}>
            {true && isSuccess && <Widget widget={data.widget} />}
            <form className={cn(styles.form)} onSubmit={handleSubmitForm}>
              <div className={styles.content}>
                {isCreating || isEditMode ? (
                  <Component isCreating={isCreating} />
                ) : (
                  <AccessRuleInfo access_rule={access_rule} />
                )}
              </div>
              <BottomBar className={cn(styles.controls)}>
                {isCreating || isEditMode ? (
                  <BottomBar.Part placement="right">
                    <CancelBooking openModal={onClose} type="secondary" />
                    <Button
                      disabled={!valid}
                      variant="primary"
                      onClick={handleClickSave}
                    >
                      {intl.formatMessage({ id: ETranslations.SAVE })}
                    </Button>
                  </BottomBar.Part>
                ) : (
                  <>
                    <BottomBar.Part placement="left">
                      <Button
                        variant="secondary"
                        onClick={toggleOpenDeleteModal}
                      >
                        {intl.formatMessage({ id: ETranslations.BASE_DELETE })}
                      </Button>
                    </BottomBar.Part>
                    <BottomBar.Part placement="right">
                      <Button variant="primary" onClick={onToggleEdit}>
                        <ICONS.Edit />
                      </Button>
                    </BottomBar.Part>
                  </>
                )}
              </BottomBar>
              {openDeleteModal && (
                <ModalPopup
                  buttons={
                    <>
                      <Button
                        type="submit"
                        variant="dangerous"
                        onClick={handleDeleteAR}
                      >
                        {intl.formatMessage({ id: ETranslations.BASE_DELETE })}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={toggleOpenDeleteModal}
                      >
                        {intl.formatMessage({ id: ETranslations.CANCEL })}
                      </Button>
                    </>
                  }
                  isOpen={openDeleteModal}
                  text={intl.formatMessage({
                    id: ETranslations.DELETE_WARNING_TEXTS,
                  })}
                  title={intl.formatMessage({
                    id: ETranslations.DELETE_AR_GROUP_QUESTION,
                  })}
                  onClose={toggleOpenDeleteModal}
                />
              )}

              {openEditModal && (
                <ModalPopup
                  buttons={
                    <>
                      <Button
                        type="submit"
                        variant="primary"
                        onClick={handleSubmitForm}
                      >
                        {intl.formatMessage({ id: ETranslations.BASE_SAVE })}
                      </Button>
                      <Button variant="secondary" onClick={toggleOpensSave}>
                        {intl.formatMessage({ id: ETranslations.CANCEL })}
                      </Button>
                    </>
                  }
                  isOpen={openEditModal}
                  needIcon={false}
                  text={intl.formatMessage({
                    id: ETranslations.IMMEDIATELY_CHANGES,
                  })}
                  title={intl.formatMessage({
                    id: ETranslations.ASK_SAVE_CHANGES,
                  })}
                  onClose={toggleOpensSave}
                />
              )}
            </form>

            {!!responseError && (
              <ModalPopup
                buttons={
                  <>
                    <Button
                      type="submit"
                      variant="dangerous"
                      onClick={handleRemoveResponseError}
                    >
                      {intl.formatMessage({ id: ETranslations.BASE_CLOSE })}
                    </Button>
                  </>
                }
                isOpen={!!responseError}
                text={responseError}
                title={intl.formatMessage({ id: ETranslations.BASE_ERROR })}
                editBooking
                onClose={handleRemoveResponseError}
              />
            )}
          </Card>
        );
      }}
    </Form>
  );
};

export default AccessRuleForm;