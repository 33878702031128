import cn from "classnames";
import { getFullName, getMaskPhone } from "common/helpers";
import { InfiniteList } from "components/InfiniteList";
import { useFeedList } from "containers/Dialogs/FeedList/useFeedList";
import {
  useFetchSourcesContactsPageQuery,
  useLazyFetchSourcesContactsPageQuery,
} from "features/api/source-api";
import type { SourceContact, SourceContactId } from "models/source.model";
import { type RefObject, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { ICONS, Input, Spinner } from "ui-kit";
import { ArrowDown, Cross, Search } from "ui-kit/ICONS/icons";

import { DataList, DataListComplexItem, DataListItem } from "./DataList";
import styles from "./SourceContactInput.module.scss";

export const SourceContactInput = ({
  label,
  placeholder,
  sourceName,
  sourceContactId,
  error,
  disabled,
  onSelect,
  onReset,
}: {
  label?: string;
  placeholder?: string;
  sourceName?: string;
  sourceContactId?: SourceContactId;
  error?: string | string[];
  disabled?: boolean;
  onSelect: (sourceContact: SourceContact | undefined) => void;
  onReset?: () => void;
}) => {
  const defaultState = {
    searchName: sourceName || "",
    showOptions: false,
  };
  const [datalistState, setDatalistState] = useState(defaultState);
  const [fetch] = useLazyFetchSourcesContactsPageQuery();
  const { data, isLoading, fulfilledTimeStamp } =
    useFetchSourcesContactsPageQuery({
      s: datalistState.searchName,
    });

  const handleCloseDetails = () => {
    setDatalistState((prev) => ({
      ...prev,
      showOptions: false,
    }));
    if (sourceName !== datalistState.searchName) {
      onSelect(undefined);
    }
  };

  useEffect(() => {
    if (sourceContactId === undefined && datalistState.searchName) {
      setDatalistState((prev) => ({
        ...prev,
        searchName: "",
      }));
    }
  }, [sourceContactId === undefined]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        datalistState.showOptions && handleCloseDetails();
      }}
    >
      <Input
        label={label}
        placeholder={placeholder}
        autoComplete="off"
        className={styles.datalist}
        value={datalistState.searchName}
        disabled={disabled}
        onClick={() =>
          setDatalistState((prev) => ({
            ...prev,
            showOptions: !prev.showOptions,
          }))
        }
        onChange={({ currentTarget: { value } }) => {
          fetch({ s: value });
          setDatalistState((prev) => ({
            ...prev,
            searchName: value,
            showOptions: true,
          }));
        }}
        onKeyDown={(e) => {
          e.key === "Enter" && handleCloseDetails();
          e.key === "Escape" && setDatalistState(defaultState);
        }}
        prefix={<Search />}
        suffix={
          datalistState.searchName ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDatalistState({ searchName: "", showOptions: true });
                onReset?.();
              }}
            >
              <Cross />
            </button>
          ) : (
            <ArrowDown
              className={cn(
                styles.arrow,
                datalistState.showOptions && styles.open,
              )}
            />
          )
        }
        datalist={
          isLoading ? (
            <Spinner />
          ) : (
            Boolean(data?.content.length) &&
            datalistState.showOptions && (
              <DataList className={styles.datalistTop}>
                <InfiniteList
                  key={fulfilledTimeStamp}
                  initialItems={data!.content}
                  fetchNextPage={(page) =>
                    fetch({ s: datalistState.searchName, page })
                  }
                  isLastPage={data?.last}
                >
                  {({ item: sourceContact, ref }) => {
                    const fullName =
                      getFullName(
                        sourceContact?.surname,
                        sourceContact?.name,
                        sourceContact?.middle_name,
                      ) || "N/A";
                    return (
                      <DataListComplexItem
                        key={sourceContact.id}
                        ref={ref as RefObject<HTMLButtonElement> | undefined}
                        value={sourceContact.id}
                        label={fullName}
                        selected={sourceContact.id === sourceContactId}
                        onClick={(e) => {
                          setDatalistState({
                            searchName: e.currentTarget.name,
                            showOptions: false,
                          });
                          onSelect(sourceContact);
                        }}
                      >
                        <ICONS.USER_PHONE className={styles.phoneIcon} />
                        <span>
                          {sourceContact.phone
                            ? getMaskPhone(sourceContact.phone)
                            : "N/A"}
                        </span>
                        <span>{fullName}</span>
                      </DataListComplexItem>
                    );
                  }}
                </InfiniteList>
              </DataList>
            )
          )
        }
        error={error}
      />
    </OutsideClickHandler>
  );
};
