import type { MutableRefObject } from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";
import { invariant } from "utils";

type DialogContext = {
  dialogRef: MutableRefObject<HTMLDialogElement | undefined>;
  formId?: string;
  openDialog: () => void;
  openDialogModal: () => void;
  closeDialog: (returnValue?: string) => void;
  cancelDialog: (returnValue?: string) => void;
};

const modalDialogContext = createContext<DialogContext>(null!);

const useDialogContext = () => {
  const value = useContext(modalDialogContext);
  return (value ||
    invariant(
      value,
      "DialogContext not passed, please wrap your components with <Dialog />",
    )) as DialogContext;
};

const useDialogContextSelector = <Selected>(
  selector: (context: DialogContext) => Selected,
) => useContextSelector<DialogContext, Selected>(modalDialogContext, selector);

export { modalDialogContext, useDialogContext, useDialogContextSelector };
