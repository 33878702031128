import { skipToken } from "@reduxjs/toolkit/query";
import {
  useCreateDailyProgramMutation,
  useFetchDailyProgramByIdQuery,
  useUpdateDailyProgramMutation,
} from "features/api/daily-program-api";
import type { DailyProgramId } from "models/daily-program";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Params } from "types/commons";
import { ETranslations } from "types/translates";
import {
  Button,
  Card,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogProvider,
  Spinner,
} from "ui-kit";
import { commonFormErrorMap, formDataToObject } from "utils";
import type { typeToFlattenedError } from "zod";

import { MAX_DATE } from "../../../../../../../constants";
import { ProgramFormSchema, type ProgramFormSchemaInput } from "../../model";
import { ProgramForm } from "../ProgramForm/ProgramForm";
import styles from "./ProgramDetailsModal.module.scss";

export const CreateProgramFooter = ({ disabled }: { disabled?: boolean }) => {
  const { formatMessage } = useIntl();
  return (
    <footer className={styles.footer}>
      <DialogCloseButton variant="secondary" disabled={disabled}>
        {formatMessage({ id: ETranslations.BASE_CANCEL })}
      </DialogCloseButton>
      <Button type="submit" variant="primary" disabled={disabled}>
        {formatMessage({ id: ETranslations.BASE_SAVE })}
      </Button>
    </footer>
  );
};

export const ProgramDetailsModal = () => {
  const { programId } = useParams<Params>();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { data } = useFetchDailyProgramByIdQuery(programId ?? skipToken);
  const [update, { isLoading }] = useUpdateDailyProgramMutation();
  const [errors, setErrors] =
    useState<typeToFlattenedError<ProgramFormSchemaInput>>();
  return (
    <DialogProvider>
      <Dialog
        initialOpenModal
        onAfterClose={() => navigate('/settings/programs')}
        className={styles.dialog}
        position="left"
      >
        <DialogContent
          className={styles.dialogContent}
          onSubmit={(e) => {
            const formData = new FormData(e.currentTarget);
            const formObj = formDataToObject(formData);
            const result = ProgramFormSchema.safeParse(formObj, {
              errorMap: commonFormErrorMap,
            });
            if (!result.success) {
              e.preventDefault();
              return setErrors(result.error.flatten());
            }
            update({
              id: +programId! as DailyProgramId,
              name: result.data.programName,
              description: result.data.description,
              repeat_days: result.data.weekdays,
              start_date: result.data.startDate,
              end_date:
                result.data.isIndefinitely || !result.data.endDate
                  ? MAX_DATE
                  : result.data.endDate,
            });
          }}
        >
          <Card as="section" className={styles.detailsCard}>
            <Card.Header
              title={formatMessage(
                { id: ETranslations.ENTITY_CREATING },
                {
                  entity: formatMessage({
                    id: ETranslations.PROGRAM_ALT_2,
                  }).toLowerCase(),
                },
              )}
              controls={<DialogCloseButton />}
            />
            {data ? (
              <ProgramForm
                programName={data.name}
                startDate={data.start_date}
                endDate={data.end_date}
                weekdays={data.repeat_days}
                description={data.description}
              />
            ) : (
              <Spinner />
            )}
            <CreateProgramFooter disabled={isLoading} />
          </Card>
        </DialogContent>
      </Dialog>
    </DialogProvider>
  );
};
