import cn from "classnames";
import { getFullName, getMaskPhone } from "common/helpers";
import { InfiniteList } from "components/InfiniteList";
import { userRoleList } from "components/settings/sub-renders/Roles/RoleList/useRoleList";
import dayjs from "dayjs";
import { restaurantSelector } from "features/AppContext";
import {
  useFetchDailyProgramPageQuery,
  useLazyFetchDailyProgramPageQuery,
} from "features/api/daily-program-api";
import type {
  DailyProgramId,
  DailyProgramsSearchDTO,
} from "models/daily-program";
import type { RestaurantId } from "models/restaurant.model";
import type { UserUUID } from "models/user.model";
import { type RefObject, memo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  NavLink,
  Outlet,
  useRouteError,
  useSearchParams,
} from "react-router-dom";
import { useCheckPermission } from "services/permissionChecker";
import { DAYS } from "types/commons";
import { ETranslations } from "types/translates";
import {
  Card,
  Collapses,
  ICONS,
  IconWithCaption,
  LinkButton,
  Spinner,
} from "ui-kit";

import { MAX_DATE } from "../../../../../../constants";
import styles from "./Programs.module.scss";
import { ProgramsHeader } from "./ProgramsHeader";

const ProgramCard = memo(
  ({
    id,
    name,
    description,
    dateRange,
    weekdays,
  }: {
    id: DailyProgramId;
    name: string;
    description: string;
    dateRange: string;
    weekdays: string;
  }) => {
    return (
      <article>
        <NavLink to={id.toString()}>
          <span>{name}</span>
          <span>{description}</span>
          <span className={styles.dateRange}>{dateRange}</span>
          <span className={styles.weekdays}>{weekdays}</span>
        </NavLink>
      </article>
    );
  },
);

const NoPrograms = ({
  message,
  isLoading,
}: {
  message?: string;
  isLoading?: boolean;
}) => {
  const { formatMessage } = useIntl();
  return isLoading ? (
    <Spinner />
  ) : (
    <p className={styles.noPrograms}>
      <strong>
        {message || formatMessage({ id: ETranslations.NOT_FOUND })}
      </strong>
    </p>
  );
};

const ProgramsInfiniteList = ({
  filter,
  initialPrograms,
}: {
  filter: { restaurant_id: RestaurantId; search_string?: string } & {
    page?: number;
    size?: number;
    sort?: string;
  };
  initialPrograms: DailyProgramsSearchDTO["content"];
}) => {
  const { formatMessage } = useIntl();
  const [fetch] = useLazyFetchDailyProgramPageQuery();
  const localWeekDays = DAYS.reduce(
    (acc, day, index) => {
      acc[day] = dayjs()
        .isoWeekday(index + 1)
        .format("dd");
      return acc;
    },
    {} as Record<(typeof DAYS)[number], string>,
  );
  return (
    <Card.Content as="ul" className={styles.programList}>
      <header className={styles.programListHeader}>
        <span>{formatMessage({ id: ETranslations.SHIFTS_NAME })}</span>
        <span>{formatMessage({ id: ETranslations.DESCRIPTION })}</span>
        <span>{formatMessage({ id: ETranslations.SHIFT_DATES })}</span>
        <span>{formatMessage({ id: ETranslations.DAYS })}</span>
      </header>
      <InfiniteList
        initialItems={initialPrograms}
        fetchNextPage={(page) =>
          fetch({
            ...filter,
            page,
          })
        }
      >
        {({ item: program, ref }) => {
          const formattedStartDate = dayjs(program.start_date).format("MMM D");
          const formattedEndDate =
            program.end_date === MAX_DATE
              ? formatMessage({ id: ETranslations.INFINITY })
              : dayjs(program.end_date).format("MMM D");
          return (
            <li
              key={program.id}
              ref={ref as RefObject<HTMLLIElement> | undefined}
            >
              <ProgramCard
                id={program.id}
                name={program.name}
                description={program.description || ""}
                dateRange={`${formattedStartDate} - ${formattedEndDate}`}
                weekdays={program.repeat_days
                  .toSorted((a, b) => DAYS.indexOf(a) - DAYS.indexOf(b))
                  .map((day) => localWeekDays[day])
                  .join(", ")}
              />
            </li>
          );
        }}
      </InfiniteList>
    </Card.Content>
  );
};

export const Programs = () => {
  const [searchParams] = useSearchParams();
  const searchString = searchParams.get("q") || "";
  const restaurant = useSelector(restaurantSelector);
  const filter = {
    restaurant_id: restaurant.restaurant_id,
    search_string: searchString,
  };
  const { data, isLoading, fulfilledTimeStamp } =
    useFetchDailyProgramPageQuery(filter);
  return (
    <>
      <ProgramsHeader />
      {data?.content.length ? (
        <ProgramsInfiniteList
          key={fulfilledTimeStamp}
          initialPrograms={data.content}
          filter={filter}
        />
      ) : (
        <NoPrograms isLoading={isLoading} />
      )}
      <Outlet />
    </>
  );
};

export const ProgramsErrorBoundary = () => {
  const routeError = useRouteError();

  const errorMessage =
    routeError instanceof Error
      ? routeError.message
      : JSON.stringify(routeError);
  const { formatMessage } = useIntl();
  return (
    <>
      <ProgramsHeader />
      <NoPrograms
        message={formatMessage(
          { id: ETranslations.ERROR_ENTITY },
          {
            entity: formatMessage({
              id: ETranslations.PROGRAMS,
            }).toLowerCase(),
            error:
              errorMessage in ETranslations
                ? formatMessage({ id: errorMessage })
                : errorMessage,
          },
        )}
      />
    </>
  );
};
