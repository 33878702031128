import { useBookingsList } from "features/api/bookings-api";
import {
  getActiveStatuses,
  getCompletedStatuses,
  useAllStatuses,
} from "features/api/dictionaries-api";
import { useEffect, useMemo, useState } from "react";
import type { OptionsType } from "react-select";
import { useLocalStorage, useUpdateEffect } from "react-use";
import type { TUseBookingList } from "types/booking";
import type { Locales } from "types/commons";
import type { OptionSelectType } from "ui-kit";

import type {
  BookingModeType,
  BookingsData,
  SelectCheckboxOption,
} from "./types";
import {
  correctExtraStatusesFilter,
  filterBookingsListData,
  filterLocalStates,
  getIncludedStatuses,
  getOptions,
  separateStatuses,
} from "./utils";

export const useBookingFilters = (
  bookingMode: BookingModeType,
  locale: Locales,
  setBookingsData: React.Dispatch<React.SetStateAction<BookingsData>>,
  isManageableTableSelected: boolean | undefined,
) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { statuses: allStatuses } = useAllStatuses();

  const { statuses, extraStatuses, waitListStatus } = separateStatuses(
    bookingMode === "active"
      ? getActiveStatuses(allStatuses)
      : getCompletedStatuses(allStatuses),
  );

  const defaultStatusFilter = useMemo(
    () =>
      bookingMode === "active"
        ? getOptions(statuses.filter((s) => s.system_name !== "IN_HALL"))
        : getOptions(statuses.filter((s) => s.category === "TERMINAL")),
    [statuses, bookingMode],
  );

  // фильтры, стартуем со всех выбранных

  const [statusFilter, setStatusFilter] = useLocalStorage<
    SelectCheckboxOption[] | null
  >(`dashboard:filter:statuses:${bookingMode}:${locale}`, defaultStatusFilter);

  const [extraStatusFilter, setExtraStatusFilter] = useLocalStorage<
    SelectCheckboxOption[] | null
  >(`dashboard:filter:extraStatuses:${bookingMode}:${locale}`, null);

  // filters extraStatusFilter from localStorage to remove disabled extra statuses.
  useEffect(() => {
    extraStatusFilter &&
      setExtraStatusFilter(
        correctExtraStatusesFilter(extraStatuses, extraStatusFilter),
      );
  }, []);

  const includedStatuses = useMemo(
    () => getIncludedStatuses(statuses, statusFilter, waitListStatus),
    [statuses, statusFilter],
  );

  const bookingPayload: TUseBookingList = {
    search: searchQuery,
    includeStatuses: includedStatuses,
    isManageableTableSelected:
      bookingMode === "active" && isManageableTableSelected,
  };

  const { data, isFetching, isLoading } = useBookingsList(bookingPayload);

  const { bookings, statistics, waitList } = filterBookingsListData(
    data,
    extraStatusFilter,
  );

  useEffect(() => {
    data &&
      setBookingsData({
        bookings,
        statistics,
        waitList,
        isLoading,
        includedStatuses,
        searchQuery,
        extraStatusFilter,
      });
  }, [extraStatusFilter, data, isLoading]);

  useUpdateEffect(() => {
    const isLocalStatusFilters = localStorage.getItem(
      `dashboard:filter:statuses:${bookingMode}:${locale}`,
    );
    if (isLocalStatusFilters) return;
    setStatusFilter(defaultStatusFilter);
  }, [defaultStatusFilter]);

  const statusOptions = getOptions(statuses);
  const extraStatusOptions = getOptions(extraStatuses, statusFilter);

  useEffect(() => {
    let localStates;
    try {
      localStates = JSON.parse(
        localStorage.getItem(
          `dashboard:filter:statuses:${bookingMode}:${locale}`,
        ) || "null",
      );
    } catch {
      console.error("Не удалось прочитать сохраненные фильтры");
    }
    const statesArray: SelectCheckboxOption[] = Array.isArray(localStates)
      ? localStates
      : [];

    const filteredStates = filterLocalStates(
      statesArray,
      statuses,
      bookingMode,
    );

    setStatusFilter(filteredStates.length ? filteredStates : statusOptions);
  }, [bookingMode]);

  const handleStatusFilterSelect = (
    options: OptionSelectType | OptionsType<OptionSelectType> | null,
  ) => {
    setStatusFilter(options as SelectCheckboxOption[] | null);
    const newExtraStatusFulter = extraStatusFilter?.filter(
      (extraStatusOption) =>
        (options as SelectCheckboxOption[] | null)?.some(
          (stateOption) => stateOption.category === extraStatusOption.category,
        ),
    );
    newExtraStatusFulter && setExtraStatusFilter(newExtraStatusFulter);
  };

  const handleExtraStatusFilterSelect = (
    options: OptionSelectType | OptionsType<OptionSelectType> | null,
  ) => {
    setExtraStatusFilter(options as SelectCheckboxOption[] | null);
  };

  return {
    isLoading: isFetching,
    searchQuery,
    setSearchQuery,
    handleStatusFilterSelect,
    handleExtraStatusFilterSelect,
    statusFilter,
    extraStatusFilter,
    statusOptions,
    extraStatusOptions,
  };
};
