import Checkbox from "common/components/checkbox";
import { restaurantSelector } from "features/AppContext";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import {
  Card,
  DatePicker,
  FieldError,
  Input,
  Labeled,
  RadioButton,
  RadioGroup,
  TextareaComposite,
} from "ui-kit";
import { renderWeekdays } from "utils";
import type { typeToFlattenedError } from "zod";

import type { ProgramFormSchemaInput } from "../../model";
import { MAX_DATE } from "./../../../../../../../constants";
import styles from "./ProgramForm.module.scss";

const DateSelector = ({
  defaultStartDate,
  defaultEndDate,
}: {
  defaultStartDate?: string;
  defaultEndDate?: string;
}) => {
  const [dateRange, setDateRange] = useState({
    from: defaultStartDate ? new Date(defaultStartDate) : new Date(),
    to: defaultEndDate ? new Date(defaultEndDate) : new Date(),
  });
  const [isIndefinitely, setIsIndefinitely] = useState<boolean>();
  const { formatMessage } = useIntl();
  return (
    <div className={styles.dateSelector}>
      <DatePicker
        label={formatMessage({ id: ETranslations.START_DATE })}
        required
        name="startDate"
        className={styles.datepicker}
        customInputClassName={styles.datePickerInput}
        customInputFormatDate="ddd, DD.MM.YYYY"
        dateFormatCalendar="MMMM"
        selected={dateRange.from}
        onChange={(newDate) =>
          newDate && setDateRange((prev) => ({ ...prev, from: newDate }))
        }
      />
      <DatePicker
        label={formatMessage({ id: ETranslations.END_DATE })}
        required
        name="endDate"
        className={styles.datepicker}
        customInputClassName={styles.datePickerInput}
        customInputFormatDate="ddd, DD.MM.YYYY"
        dateFormatCalendar="MMMM"
        selected={dateRange.to}
        minDate={dateRange.from}
        disabled={isIndefinitely}
        onChange={(newDate) =>
          newDate && setDateRange((prev) => ({ ...prev, to: newDate }))
        }
      />
      <Checkbox
        name="isIndefinitely"
        defaultChecked={defaultEndDate === MAX_DATE}
        value={isIndefinitely}
        onChange={setIsIndefinitely}
        text={formatMessage({ id: ETranslations.CONTINUE_INDEFINITE })}
      />
    </div>
  );
};

export const ProgramForm = ({
  errors,
  ...inputs
}: {
  errors?: typeToFlattenedError<ProgramFormSchemaInput>;
} & Partial<ProgramFormSchemaInput>) => {
  const { formatMessage } = useIntl();
  const restaurant = useSelector(restaurantSelector);
  return (
    <Card.Content as="fieldset" className={styles.programForm}>
      <input
        type="hidden"
        name="restaurantId"
        readOnly
        defaultValue={inputs.restaurantId || restaurant.restaurant_id}
      />
      <Input
        defaultValue={inputs.programName}
        label={formatMessage({ id: ETranslations.SHIFTS_NAME })}
        name="programName"
        maxLength={100}
        required
        autoComplete="off"
        error={errors?.fieldErrors.programName}
      />
      <DateSelector
        defaultStartDate={inputs.startDate}
        defaultEndDate={inputs.endDate}
      />
      {errors?.fieldErrors.startDate && (
        <FieldError error={errors?.fieldErrors.startDate} />
      )}
      {errors?.fieldErrors.endDate && (
        <FieldError error={errors?.fieldErrors.endDate} />
      )}
      <RadioGroup.Root
        groupName="weekdays"
        required
        label={formatMessage({ id: ETranslations.DAYS_TO_CONTINUE_THIS_SHIFT })}
        variant="bordered"
        multiple
        defaultValue={
          inputs.weekdays &&
          (Array.isArray(inputs.weekdays) ? inputs.weekdays : [inputs.weekdays])
        }
        error={errors?.fieldErrors.weekdays}
      >
        <RadioGroup.Content className={styles.weekdays}>
          {renderWeekdays((weekday, dayValue) => (
            <RadioGroup.Button
              key={weekday}
              value={dayValue}
              className={styles.weekday}
            >
              {weekday}
            </RadioGroup.Button>
          ))}
        </RadioGroup.Content>
      </RadioGroup.Root>
      <TextareaComposite.Root
        name="description"
        required
        className={styles.textareaContainer}
        label={formatMessage({
          id: ETranslations.DESCRIPTION,
        })}
        defaultValue={inputs.description}
        maxLength={1000}
        error={errors?.fieldErrors.description}
      >
        <TextareaComposite.Counter className={styles.textareaCounter} />
        <TextareaComposite.Input className={styles.textarea} />
      </TextareaComposite.Root>
      {errors?.formErrors && <FieldError error={errors.formErrors} />}
    </Card.Content>
  );
};
