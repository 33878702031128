import { AccessRulesModal } from "components/shifts/Modal/AccessRulesModal/AccessRulesModal";
import type { AccessRuleGroup } from "models/accessRules.model";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Collapse } from "ui-kit";

import styles from "./EachAccessRule.module.scss";
import EachRule from "./EachRule";

const EachAccessRule = (props: AccessRuleGroup) => {
  const isActive = props.access_rules.some((each) => each.active);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const intl = useIntl();

  const modalOnClose = useCallback(() => {
    setModalOpen(false);
    setIsEditMode(false);
  }, []);
  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setModalOpen(() => true);
  };

  return (
    <Collapse
      expendInHeader={true}
      header={
        <div className={styles.header}>
          <span>{props.name} </span>
          <div className={styles.restInfo}>
            <button className={styles[isActive ? "active" : "inactive"]}>
              {intl.formatMessage({
                id: isActive
                  ? ETranslations.SHIFT_STATUS_ACTIVE
                  : ETranslations.SHIFT_STATUS_INACTIVE,
              })}
            </button>
            <span className={styles.open} onClick={handleOpen}>
              {intl.formatMessage({ id: ETranslations.BASE_INFO })}
            </span>
          </div>
        </div>
      }
      headerClassName={styles.accessRuleHeader}
      iconClassName={styles.icon}
    >
      <table className={styles.rules}>
        <tbody>
          {props.access_rules.map((rule) => {
            return (
              <EachRule key={rule.rule_name} rule={rule} ruleGroup={props} />
            );
          })}
        </tbody>
      </table>
      <AccessRulesModal
        access_rule={props}
        isEditMode={isEditMode}
        isOpen={modalOpen}
        setIsEditMode={setIsEditMode}
        title={props.name}
        onClose={modalOnClose}
      />
    </Collapse>
  );
};

export default EachAccessRule;
