import cn from "classnames";
import {
  type AllHTMLAttributes,
  type ButtonHTMLAttributes,
  type HTMLAttributes,
  type OptgroupHTMLAttributes,
  type OptionHTMLAttributes,
  type ReactNode,
  forwardRef,
} from "react";

import styles from "./DataList.module.scss";

export const DataListItem = forwardRef<
  HTMLOptionElement,
  OptionHTMLAttributes<HTMLOptionElement> & { children?: string }
>((props, ref) => (
  <option
    className={cn(styles.item, props.className)}
    label={props.label || props.children}
    ref={ref}
    {...props}
  />
));

export const DataListGroup = forwardRef<
  HTMLOptGroupElement,
  OptgroupHTMLAttributes<HTMLOptGroupElement>
>((props, ref) => (
  <optgroup
    className={cn(styles.group, props.className)}
    label={props.label}
    ref={ref}
  >
    {props.children}
  </optgroup>
));

export const DataListComplexItem = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & {
    selected?: boolean;
    label?: string;
  }
>(({ children, label, selected, ...props }, ref) => (
  <button
    role="option"
    aria-selected={selected}
    type="button"
    className={cn(styles.item, styles.complexItem, props.className)}
    name={label}
    ref={ref}
    {...props}
  >
    {children}
  </button>
));

export const DataList = ({
  className,
  children,
  onSelect,
}: {
  className?: string;
  children: ReactNode;
  onSelect?: (value: string, label: string) => void;
}) => {
  return (
    <datalist
      className={cn(styles.datalist, className)}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        const element = e.target;

        if (element instanceof HTMLOptionElement) {
          onSelect?.(element.value, element.label);
        }
      }}
    >
      {children}
    </datalist>
  );
};
