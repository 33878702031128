import { CloseBookingContext } from "contexts/CloseBookingContext";
import { useCallback, useContext, useEffect } from "react";

import { useFromProxyActions } from "../../../features/BookingFormProxy";
import { useCreateOrderMutation } from "../../../features/api/booking-order-api";

const useEditOrder = () => {
  const [createOrder] = useCreateOrderMutation();
  const update = useCallback(
    async (data) => {
      await createOrder(data).unwrap();
    },
    [createOrder],
  );

  return {
    update,
  };
};

export default useEditOrder;
