import type { ConfirmDialogProps } from "components/ConfirmModal";
import {
  useCreateNoteMutation,
  useUpdateNoteMutation,
} from "features/api/notes-api";
import type { NoteId, NoteRecordId } from "models/restaurant-notes.model";
import type { RestaurantId } from "models/restaurant.model";
import { useRef } from "react";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";
import {
  Button,
  Card,
  TextareaComposite,
  useDialogContextSelector,
} from "ui-kit";

import { MAX_NOTE_LENGTH } from "../constants";
import styles from "./NoteForm.module.scss";

export const NoteForm = ({
  restaurantId,
  date,
  intent,
  disabled,
  noteInfo,
  onCancel,
  showConfirmDialog,
}: {
  restaurantId: RestaurantId;
  date: string;
  intent: "create" | "edit";
  disabled?: boolean;
  noteInfo?: {
    noteId: NoteId;
    noteRecordId: NoteRecordId;
    noteText: string;
  };
  onCancel: () => void;
  showConfirmDialog: (params: ConfirmDialogProps | null) => void;
}) => {
  const { formatMessage } = useIntl();
  const [createNote, createMutationState] = useCreateNoteMutation();
  const [updateNote, updateMutationState] = useUpdateNoteMutation();
  const closeDialog = useDialogContextSelector((c) => c.closeDialog);
  const handleCreateNote = (noteText: string) =>
    createNote({ date, restaurantId, noteText }).then((d) => {
      const success = Object.hasOwn(d, "data");
      if (success) {
        Notification.success(ETranslations.NOTE_SUCCESSFULLY_CREATED);
        closeDialog();
      } else {
        Notification.error({
          title: ETranslations.NOTE_CREATION_FAILED,
          message: JSON.stringify(createMutationState.error),
        });
      }
    });
  const handleUpdateNote =
    noteInfo &&
    ((noteText: string) =>
      updateNote({
        noteId: noteInfo.noteId,
        noteRecordId: noteInfo.noteRecordId,
        noteText,
      }).then((d) => {
        const success = Object.hasOwn(d, "data");
        if (success) {
          Notification.success(ETranslations.NOTE_SUCCESSFULLY_MODIFIED);
          closeDialog();
        } else {
          Notification.error({
            title: ETranslations.NOTE_MODIFICATION_FAILED,
            message: JSON.stringify(updateMutationState.error),
          });
        }
      }));

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  return (
    <>
      <Card.Header
        title={formatMessage(
          {
            id:
              intent === "create"
                ? ETranslations.ENTITY_CREATING
                : ETranslations.ENTITY_EDITING,
          },
          { entity: formatMessage({ id: ETranslations.A_NOTE }) },
        )}
      />
      <Card.Content>
        <TextareaComposite.Root
          name="note"
          className={styles.textareaContainer}
          label={formatMessage({
            id: ETranslations.NOTE,
          })}
          defaultValue={noteInfo?.noteText}
          maxLength={MAX_NOTE_LENGTH}
        >
          <TextareaComposite.Counter className={styles.textareaCounter} />
          <TextareaComposite.Input
            className={styles.textarea}
            disabled={disabled}
            ref={textAreaRef}
          />
        </TextareaComposite.Root>
      </Card.Content>
      <footer className={styles.footer}>
        <Button
          variant="primary"
          type="submit"
          disabled={
            intent === "create"
              ? createMutationState.isLoading
              : updateMutationState.isLoading
          }
          onClick={() =>
            textAreaRef.current?.value &&
            (intent === "create"
              ? handleCreateNote(textAreaRef.current.value)
              : handleUpdateNote!(textAreaRef.current.value))
          }
        >
          {formatMessage({ id: ETranslations.BASE_SAVE })}
        </Button>
        <Button variant="secondary" type="reset" onClick={onCancel}>
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
        </Button>
      </footer>
    </>
  );
};
