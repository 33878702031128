import { NotificationDTO } from "models/notification.model";

import { IResponse } from "../../models/common";
import { RequiredBy } from "./../../types/commons";
import { api } from "./api";

export const notificationApi = api
  .enhanceEndpoints({
    addTagTypes: ["Notifications"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchNotifications: build.query<NotificationDTO, number>({
        query: (shiftId) => ({
          url: `v2/notifications/${shiftId}`,
          method: "GET",
        }),
        transformResponse: (response: IResponse<NotificationDTO>) => {
          const result = NotificationDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: (r, e, shiftId) => [
          { type: "Notifications", id: shiftId },
        ],
      }),
      updateNotifications: build.mutation<
        {},
        RequiredBy<NotificationDTO, "settings"> & { shiftId: number }
      >({
        query: (data) => ({
          url: `v2/notifications/${data.shiftId}`,
          method: "POST",
          body: {
            settings: data.settings,
            variables: data.variables,
          },
        }),
        invalidatesTags: (r, e, args) => [
          { type: "Notifications", id: args.shiftId },
        ],
        async onQueryStarted(args, { queryFulfilled }) {
          const { data } = await queryFulfilled;
          //@ts-ignore
          if (data?.status === "ERROR") {
            throw data;
          }
        },
      }),
    }),
  });

export const { useFetchNotificationsQuery, useUpdateNotificationsMutation } =
  notificationApi;
