import {
  OrganizationId,
  SourceContactId,
  SourceType,
} from "models/source.model";
import type { IntlShape } from "react-intl";
import { ETranslations } from "types/translates";
import { z } from "zod";

const getSourceContactSchema = (t: IntlShape["formatMessage"]) => ({
  sourceContactId: z
    .string()
    .transform(Number)
    .pipe(SourceContactId)
    .optional(),
  surname: z.union([
    z.string().trim().pipe(z.literal("")),
    z
      .string()
      .trim()
      .regex(
        /^[\p{sc=Cyrillic}|\p{sc=Latin}]+$/u,
        ETranslations.ONLY_LATIN_CYRILLIC,
      )
      .max(30, t({ id: ETranslations.FIELD_TOO_LONG }, { max: 30 }))
      .optional(),
  ]),
  name: z
    .union([
      z.string().trim().pipe(z.literal("")),
      z
        .string()
        .trim()
        .regex(
          /^[\p{sc=Cyrillic}|\p{sc=Latin}]+$/u,
          ETranslations.ONLY_LATIN_CYRILLIC,
        )
        .max(30, t({ id: ETranslations.FIELD_TOO_LONG }, { max: 30 }))
        .min(1, ETranslations.FIELD_CANT_BE_EMPTY),
    ])
    .optional(),
  middleName: z.union([
    z.string().trim().pipe(z.literal("")),
    z
      .string()
      .trim()
      .regex(
        /^[\p{sc=Cyrillic}|\p{sc=Latin}]+$/u,
        ETranslations.ONLY_LATIN_CYRILLIC,
      )
      .max(30, t({ id: ETranslations.FIELD_TOO_LONG }, { max: 30 }))
      .optional(),
  ]),
  phone: z
    .string()
    .trim()
    .regex(/^\+?[1-9]\d{1,14}$/, ETranslations.FIELD_INVALID)
    .min(1, ETranslations.FIELD_CANT_BE_EMPTY)
    .optional(),
  additionalPhone: z.union([
    z.string().trim().pipe(z.literal("")),
    z
      .string()
      .trim()
      .regex(/^\+?[1-9]\d{1,14}$/, ETranslations.FIELD_INVALID)
      .optional(),
  ]),
  email: z
    .union([
      z.string().trim().pipe(z.literal("")),
      z
        .string()
        .email({ message: ETranslations.FIELD_INVALID })
        .max(50, t({ id: ETranslations.FIELD_TOO_LONG }, { max: 50 })),
    ])
    .optional(),
});

const getSourceOrganizationSchema = (t: IntlShape["formatMessage"]) => ({
  organizationId: z.string().transform(Number).pipe(OrganizationId).optional(),
  organizationName: z
    .union([
      z.string().trim().pipe(z.literal("")),
      z.string().trim().nonempty(),
    ])
    .optional(),
  organizationPhone: z
    .union([
      z.string().trim().pipe(z.literal("")),
      z
        .string()
        .trim()
        .regex(/^\+?[1-9]\d{1,14}$/, ETranslations.FIELD_INVALID)
        .min(1, ETranslations.FIELD_CANT_BE_EMPTY),
    ])
    .optional(),
  organizationEmail: z
    .union([
      z.string().trim().pipe(z.literal("")),
      z
        .string()
        .email({ message: ETranslations.FIELD_INVALID })
        .max(50, t({ id: ETranslations.FIELD_TOO_LONG }, { max: 50 })),
    ])
    .optional(),
  organizationAddress: z
    .union([
      z.string().trim().pipe(z.literal("")),
      z.string().max(50, t({ id: ETranslations.FIELD_TOO_LONG }, { max: 50 })),
    ])
    .optional(),
});

export const getSourceFormSchema = (t: IntlShape["formatMessage"]) =>
  z.object({
    ...getSourceContactSchema(t),
    ...getSourceOrganizationSchema(t),
    type: SourceType.optional(),
  });

export type SourceFormSchemaInput = z.input<
  ReturnType<typeof getSourceFormSchema>
>;
export type SourceFormSchema = z.infer<ReturnType<typeof getSourceFormSchema>>;
