import { ETranslations } from "types/translates";
import { z } from "zod";


// TODO: убрать с переходом на zod v4
export function zDiscriminatedUnion<
  T extends readonly [z.ZodTypeAny, z.ZodTypeAny, ...z.ZodTypeAny[]],
>(key: string, types: T): z.ZodUnion<T>;
export function zDiscriminatedUnion(key: string, types: z.ZodTypeAny[]): any {
  const optionsMap = types.reduce((result, type) => {
    const value = (type instanceof z.ZodEffects ? type.sourceType() : type)
      .shape[key];
    if (!(value instanceof z.ZodLiteral) || result.has(value.value)) {
      throw new Error("cannot construct discriminated union");
    }
    result.set(value.value, type);
    return result;
  }, new Map());

  return new z.ZodDiscriminatedUnion({
    typeName: z.ZodFirstPartyTypeKind.ZodDiscriminatedUnion,
    discriminator: key,
    options: types as any,
    optionsMap,
  });
}

export const commonFormErrorMap: z.ZodErrorMap = (error, ctx) => ({
  message:
    error.code === z.ZodIssueCode.invalid_type && error.received === "undefined"
      ? ETranslations.ERROR_REQUIRED_FIELD
      : ctx.defaultError,
});