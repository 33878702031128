import dayjs, { type Dayjs } from "dayjs";
import { type Dispatch, type SetStateAction, useRef, useState } from "react";
import { Button } from "ui-kit";
import { DetailsCollapse } from "ui-kit/Collapse/Collapse";
import { LeftArrow, RightArrow } from "ui-kit/ICONS/icons";

import styles from "./MonthSelect.module.scss";

const getAdjustedMonthNumber = (startMonth: number, add: number) =>
  (startMonth + add) % 12;

export const MonthSelect = ({
  currentMonth,
  setCurrentMonth,
}: {
  currentMonth: Dayjs;
  setCurrentMonth: Dispatch<SetStateAction<Dayjs>>;
}) => {
  const currentLocaleData = dayjs.localeData();
  const months = currentLocaleData.months();
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const now = dayjs().tz();
  const nowMonth = now.startOf("month");
  const todaysMonth = now.month();
  const todaysYear = now.year();
  const monthsDifferenceToToday = currentMonth.diff(nowMonth, "month");
  return (
    <header className={styles.header}>
      <Button
        variant="phantom"
        onClick={() => setCurrentMonth((prev) => prev.add(-1, "month"))}
      >
        <LeftArrow />
      </Button>
      <div>
        <DetailsCollapse
          title={currentMonth.format("MMMM, YYYY")}
          className={styles.dateSelect}
          contentClassName={styles.dateOptions}
          icon={false}
          ref={detailsRef}
        >
          <ol
            style={{ "--start-year": todaysYear }}
            onChange={(e) => {
              e.stopPropagation();
              if (e.target instanceof HTMLInputElement) {
                setCurrentMonth(nowMonth.add(Number(e.target.value), "month"));
                detailsRef.current && (detailsRef.current.open = false);
              }
            }}
          >
            {Array.from({ length: 24 }, (v, k) => (
              <li
                key={k}
                data-start-of-year={
                  !getAdjustedMonthNumber(todaysMonth, k) || undefined
                }
              >
                <input
                  id={`month-${k}`}
                  name="month-select"
                  value={k}
                  readOnly
                  checked={k === monthsDifferenceToToday}
                  type="radio"
                  hidden
                />
                <label htmlFor={`month-${k}`} className={styles.dateOption}>
                  {months[getAdjustedMonthNumber(todaysMonth, k)]}
                </label>
              </li>
            ))}
          </ol>
        </DetailsCollapse>
      </div>
      <Button
        variant="phantom"
        onClick={() => setCurrentMonth((prev) => prev.add(1, "month"))}
      >
        <RightArrow />
      </Button>
    </header>
  );
};
