import { useFetchSourcesPageQuery } from "features/api/source-api";
import type { SourceId, SourceSearchParams } from "models/source.model";
import { useState } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Card, Input, SelectCheckbox, Spinner } from "ui-kit";



import { TAGS_TITLES } from "../../../../../constants";
import { SourceInfiniteList } from "./SourceInfiniteList";
import styles from "./SourceList.module.scss";


type Tab = "Contacts" | "Organizations";

export type SelectSourceFn = ({
  id,
  fullName,
  phone,
}: {
  id: SourceId;
  fullName: string;
  phone?: string;
}) => void;

export const SourceList = () => {
  const { formatMessage } = useIntl();
  const [filter, setFilter] = useState<SourceSearchParams>({
    s: "",
  });
  const { data: contacts, fulfilledTimeStamp: contactsKey } =
    useFetchSourcesPageQuery({ ...filter, sort: "contacts" });
  const { data: organizations, fulfilledTimeStamp: organizationsKey } =
    useFetchSourcesPageQuery({ ...filter, sort: "organizations" });
  const [activeTab, setActiveTab] = useState<Tab>("Contacts");
  return !organizations || !contacts ? (
    <Spinner />
  ) : (
    <Card className={styles.list} as="section">
      <section className={styles.subHeader}>
        <Input.Search
          onSearch={(value) => setFilter((prev) => ({ ...prev, s: value }))}
        />
        <SelectCheckbox
          options={[
            {
              value: "CONCIERGE",
              label: "CONCIERGE",
            },
            { value: "PERSONAL_ASSISTANT", label: "PERSONAL_ASSISTANT" },
            { value: "LIFESTYLE_MANAGER", label: "LIFESTYLE_MANAGER" },
            { value: "PRIME_CONCIERGE", label: "PRIME_CONCIERGE" },
            { value: "OPEN_SOURCE", label: "OPEN_SOURCE" },
          ]}
          placeholder={formatMessage(
            { id: ETranslations.BASE_SELECT_ENTITY },
            {
              entity: formatMessage({
                id: ETranslations.TYPE,
              }).toLowerCase(),
            },
          )}
          titles={TAGS_TITLES}
          onChange={(option) =>
            setFilter((prev) => ({
              ...prev,
              labels: Array.isArray(option)
                ? option.map((o) => o.value)
                : option
                  ? [
                      (
                        option as {
                          label: string;
                          value: string | number;
                        } | null
                      )?.value,
                    ]
                  : undefined,
            }))
          }
        />
      </section>
      <Card.HeaderTabs<Tab>
        className={styles.tabs}
        activeTab={activeTab}
        onChange={setActiveTab}
      >
        <Card.HeaderTabs.Item<Tab>
          tabName={"Contacts"}
          title={`${formatMessage({ id: ETranslations.CONTACTS })} (${contacts.totalElements})`}
        >
          <SourceInfiniteList
            key={contactsKey}
            filter={{ ...filter, sort: "contacts" }}
            initialSources={contacts.content}
          />
        </Card.HeaderTabs.Item>
        <Card.HeaderTabs.Item<Tab>
          tabName={"Organizations"}
          title={`${formatMessage({ id: ETranslations.ORGANIZATIONS })} 
          (${organizations.totalElements})`}
        >
          <SourceInfiniteList
            key={organizationsKey}
            filter={{ ...filter, sort: "organizations" }}
            initialSources={organizations.content}
          />
        </Card.HeaderTabs.Item>
      </Card.HeaderTabs>
    </Card>
  );
};