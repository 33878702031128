import {
  getTimeHoursAndMinutes,
  instanceOfHistory,
  matchingIconsAndTranslateToStatus,
} from "common/helpers";
import {
  restaurantsSelector,
  useApplicationContextActions,
} from "features/AppContext";
import {
  useAllStatuses,
  useCompleteStatuses,
} from "features/api/dictionaries-api";
import type { BookingHistoryDTO } from "models/history.model";
import moment from "moment";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { type BookingOrder } from "types/booking";
import { ICONS } from "ui-kit";

import { HOURS_TITLES, MINUTES_TITLES } from "../../constants";
import { useFromProxyActions } from "../../features/BookingFormProxy";
import { ETranslations } from "../../types/translates";
import { declOfNum } from "../../utils";
import { TModeHistory } from "./BookingClientHistory";
import styles from "./styles.module.scss";

const { Calendar, Clock, Guests, HallIcon, RestaurantIcon, SeatIcon } = ICONS;

interface Props {
  booking: BookingHistoryDTO["booking"] | BookingOrder;
  mode: TModeHistory;
}

export const BookingHistoryItem: FC<Props> = ({ booking, mode }) => {
  const intl = useIntl();
  const restaurants = useSelector(restaurantsSelector);
  const { setOnlyBooking, setOnlyOrder } = useFromProxyActions();
  const { setDate } = useApplicationContextActions();
  const navigate = useNavigate();
  const { data: completeStatus } = useCompleteStatuses();
  const { setPlaceFromBooking, setPlaceFromOrder, setRestaurant } =
    useApplicationContextActions();
  const { minutes, hours } = getTimeHoursAndMinutes(
    "orderId" in booking ? booking.visitTime : booking.visit_duration,
  );
  const isBooking = mode === "booking";
  if (!booking) return null;
  const { statusById } = useAllStatuses();
  const bookingStatus =
    instanceOfHistory(booking) && statusById && statusById[booking.status_id];
  const bookingRestaurant =
    instanceOfHistory(booking) &&
    restaurants.find((r) => r.restaurant_id === booking.restaurant_id);
  const statusObject =
    isBooking &&
    instanceOfHistory(booking) &&
    bookingStatus &&
    matchingIconsAndTranslateToStatus[bookingStatus.system_name];
  const date = "orderId" in booking ? booking.bookingDate : booking.date;
  const time = "orderId" in booking ? booking.bookingTime : booking.time;

  const restaurantName = useMemo(() => {
    if (!instanceOfHistory(booking)) {
      if (!booking.places) return "";
      const { placeId } = booking.places;
      return restaurants.find((rest) =>
        rest.places.some((place) => place.id === placeId),
      )?.restaurant_name;
    }
    return "";
  }, [booking, restaurants]);

  const restaurant = useMemo(() => {
    if (instanceOfHistory(booking)) {
      return restaurants.find(
        (el) => el.restaurant_id === booking.restaurant_id,
      );
    } else {
      const placeId = booking.places.placeId;
      return restaurants.find((el) =>
        el.places.some((place) => place.id === placeId),
      );
    }
  }, [booking, restaurants]);

  const clickHandler = () => {
    let url = "";
    if (restaurant) setRestaurant(restaurant);
    if (instanceOfHistory(booking)) {
      url = `/dashboard/booking/${booking.id}/info`;
      if (completeStatus.some((el) => el.id === booking.status_id)) {
        url += "?mode=complete";
      }
      // todo: remove hardcode
      if (bookingStatus && bookingStatus.system_name === "WAIT_LIST") {
        url += "?mode=waitList";
      }
      setPlaceFromBooking(booking);
      setOnlyBooking(booking);
    } else {
      url = "/requests";
      setPlaceFromOrder(booking);
      setOnlyOrder(booking);
    }
    setDate(new Date(date).toISOString());
    navigate(url);
  };

  return (
    <div className={styles.listItem} onClick={clickHandler}>
      <span>
        <RestaurantIcon height={22} width={22} />
        <strong>
          {isBooking && instanceOfHistory(booking)
            ? bookingRestaurant && bookingRestaurant.restaurant_name
            : restaurantName}
        </strong>
      </span>
      <span>
        <Calendar />
        {moment(date).format("DD.MM.YYYY")}
      </span>
      <span>
        <Clock />
        {moment(`${date} ${time}`).format("HH:mm")}
      </span>
      <span>
        <HallIcon height={20} width={20} />
        {instanceOfHistory(booking)
          ? booking.slot_places[booking.slot_places.length - 1]?.hall_name
          : "-"}
      </span>
      <span>
        <Guests height={20} width={20} />
        {"orderId" in booking ? booking.persons : booking.visitors}
      </span>
      <span>
        <SeatIcon />
        {instanceOfHistory(booking)
          ? booking.slot_places[booking.slot_places.length - 1]?.table_name
          : intl.formatMessage({ id: ETranslations.WITHOUT_SEAT })}
      </span>
      <span>
        <Clock />
        {`${hours} ${intl.formatMessage({ id: declOfNum(hours, HOURS_TITLES) })}
         ${minutes} ${intl.formatMessage({
           id: declOfNum(minutes, MINUTES_TITLES),
         })}`.toLowerCase()}
      </span>
      <span>
        {statusObject ? (
          <>
            <img alt="status" src={statusObject.icon} />
            <span>
              {intl.formatMessage({
                id: statusObject.text,
              })}
            </span>
          </>
        ) : (
          intl.formatMessage({ id: ETranslations.PLURAL_REQUEST })
        )}
      </span>
    </div>
  );
};
