import cn from "classnames";
import { getMaskPhone } from "common/helpers";
import { InfiniteList } from "components/InfiniteList";
import {
  useFetchOrganizationPageQuery,
  useLazyFetchOrganizationPageQuery,
} from "features/api/source-api";
import { type Organization, OrganizationId } from "models/source.model";
import { type RefObject, useCallback, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { ICONS, Input, Spinner } from "ui-kit";
import { ArrowDown, Cross } from "ui-kit/ICONS/icons";
import { debounce } from "utils";

import { DataList, DataListComplexItem } from "./DataList";
import styles from "./SourceInput.module.scss";

export const OrganizationInput = ({
  label,
  organizationName,
  organizationId,
  error,
  placeholder,
  onSelect,
  onReset,
}: {
  label?: string;
  organizationName?: string;
  organizationId?: OrganizationId;
  error?: string | string[];
  placeholder?: string;
  onSelect: (organization: Organization | undefined) => void;
  onReset?: () => void;
}) => {
  const defaultState = {
    searchName: organizationName || "",
    showOptions: false,
  };
  const [datalistState, setDatalistState] = useState(defaultState);
  const [searchName, setSearchName] = useState(defaultState.searchName);
  const [fetch] = useLazyFetchOrganizationPageQuery();
  const debouncedSetSearchName = useCallback(debounce(setSearchName, 300), []);
  const { data, isLoading, fulfilledTimeStamp } = useFetchOrganizationPageQuery(
    {
      s: searchName,
    },
  );

  const handleCloseDetails = () => {
    setDatalistState((prev) => ({
      ...prev,
      showOptions: false,
    }));
    if (organizationName !== datalistState.searchName) {
      onSelect(undefined);
    }
  };

  useEffect(() => {
    if (organizationId === undefined && datalistState.searchName) {
      setDatalistState((prev) => ({
        ...prev,
        searchName: "",
      }));
    }
  }, [organizationId === undefined]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        datalistState.showOptions && handleCloseDetails();
      }}
    >
      <Input
        label={label}
        placeholder={placeholder}
        autoComplete="off"
        required
        className={styles.datalist}
        value={datalistState.searchName}
        onClick={() =>
          setDatalistState((prev) => ({
            ...prev,
            showOptions: !prev.showOptions,
          }))
        }
        onChange={({ currentTarget: { value } }) => {
          setDatalistState((prev) => ({
            ...prev,
            searchName: value,
            showOptions: true,
          }));
          debouncedSetSearchName(value);
        }}
        onKeyDown={(e) => {
          e.key === "Enter" && handleCloseDetails();
          e.key === "Escape" && setDatalistState(defaultState);
        }}
        suffix={
          datalistState.searchName ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDatalistState({ searchName: "", showOptions: true });
                onReset?.();
              }}
            >
              <Cross />
            </button>
          ) : (
            <ArrowDown
              className={cn(
                styles.arrow,
                datalistState.showOptions && styles.open,
              )}
            />
          )
        }
        datalist={
          isLoading ? (
            <Spinner />
          ) : (
            Boolean(data?.content.length) &&
            datalistState.showOptions && (
              <DataList className={styles.datalistTop}>
                <InfiniteList
                  key={fulfilledTimeStamp}
                  initialItems={data!.content}
                  fetchNextPage={(page) =>
                    fetch({ s: datalistState.searchName, page })
                  }
                >
                  {({ item: organization, ref }) => (
                    <DataListComplexItem
                      key={organization.id}
                      ref={ref as RefObject<HTMLButtonElement> | undefined}
                      value={organization.id}
                      label={organization.source_name}
                      selected={organization.id === organizationId}
                      onClick={(e) => {
                        setDatalistState({
                          searchName: e.currentTarget.name,
                          showOptions: false,
                        });
                        onSelect(organization);
                      }}
                    >
                      {organization.phone && (
                        <>
                          <ICONS.USER_PHONE />
                          <span>{getMaskPhone(organization.phone)}</span>
                        </>
                      )}
                      <span>{organization.source_name}</span>
                    </DataListComplexItem>
                  )}
                </InfiniteList>
              </DataList>
            )
          )
        }
        error={error}
      />
    </OutsideClickHandler>
  );
};
