import {
  useFetchOrganizationPageQuery,
  useFetchSourcesContactsPageQuery,
  useFetchSourcesPageQuery,
} from "features/api/source-api";
import type {
  OrganizationId,
  SourceContactId,
  SourceId,
  SourceSearchDTO,
  SourceSearchParams,
} from "models/source.model";
import { type ReactNode, useState } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import {
  Card,
  DialogCloseButton,
  Input,
  SelectCheckbox,
  Spinner,
} from "ui-kit";

import { TAGS_TITLES } from "../../../../../constants";
import { OrganizationInfiniteList } from "./OrganizationInfiniteList";
import { SourceContactInfiniteList } from "./SourceContactInfiniteList";
import { SourceInfiniteList } from "./SourceInfiniteList";
import type { SelectSourceFn } from "./SourceList";
import styles from "./SourceList.module.scss";

type Tab = "Contacts" | "Organizations";

export const ExternalSourceList = ({
  onSelect,
}: {
  onSelect: SelectSourceFn;
}) => {
  const { formatMessage } = useIntl();
  const [filter, setFilter] = useState<SourceSearchParams>({
    s: "",
  });
  const { data: contacts, fulfilledTimeStamp: contactsKey } =
    useFetchSourcesPageQuery({ ...filter, sort: "contacts" });
  const { data: organizations, fulfilledTimeStamp: organizationsKey } =
    useFetchSourcesPageQuery({ ...filter, sort: "organizations" });
  const [activeTab, setActiveTab] = useState<Tab>("Contacts");
  return (
    <Card className={styles.list} as="section">
      <Card.Header
        title={formatMessage({ id: ETranslations.SOURCES })}
        controls={<DialogCloseButton className={styles.close} />}
      />
      <section className={styles.subHeader}>
        <Input.Search
          onSearch={(value) => setFilter((prev) => ({ ...prev, s: value }))}
        />
        <SelectCheckbox
          options={[
            {
              value: "CONCIERGE",
              label: "CONCIERGE",
            },
            { value: "PERSONAL_ASSISTANT", label: "PERSONAL_ASSISTANT" },
            { value: "LIFESTYLE_MANAGER", label: "LIFESTYLE_MANAGER" },
            { value: "PRIME_CONCIERGE", label: "PRIME_CONCIERGE" },
            { value: "OPEN_SOURCE", label: "OPEN_SOURCE" },
          ]}
          placeholder={formatMessage(
            { id: ETranslations.BASE_SELECT_ENTITY },
            {
              entity: formatMessage({
                id: ETranslations.TYPE,
              }).toLowerCase(),
            },
          )}
          titles={TAGS_TITLES}
          onChange={(option) =>
            setFilter((prev) => ({
              ...prev,
              labels: Array.isArray(option)
                ? option.map((o) => o.value)
                : option
                  ? [
                      (
                        option as {
                          label: string;
                          value: string | number;
                        } | null
                      )?.value,
                    ]
                  : undefined,
            }))
          }
        />
      </section>
      {!organizations || !contacts ? (
        <Spinner />
      ) : (
        <Card.HeaderTabs<Tab>
          className={styles.tabs}
          activeTab={activeTab}
          onChange={setActiveTab}
        >
          <Card.HeaderTabs.Item<Tab>
            tabName={"Contacts"}
            title={`${formatMessage({ id: ETranslations.CONTACTS })} (${contacts.totalElements})`}
          >
            <SourceInfiniteList
              key={contactsKey}
              filter={{ ...filter, sort: "contacts" }}
              initialSources={contacts.content}
              onSelect={onSelect}
            />
          </Card.HeaderTabs.Item>
          <Card.HeaderTabs.Item<Tab>
            tabName={"Organizations"}
            title={`${formatMessage({ id: ETranslations.ORGANIZATIONS })} 
          (${organizations.totalElements})`}
          >
            <SourceInfiniteList
              key={organizationsKey}
              filter={{ ...filter, sort: "organizations" }}
              initialSources={organizations.content}
              onSelect={onSelect}
            />
          </Card.HeaderTabs.Item>
        </Card.HeaderTabs>
      )}
    </Card>
  );
};
