import cn from "classnames";
import { formatMinutesDuration, getFullName } from "common/helpers";
import { BookingActions } from "components/BookingActions";
import { OrderActions } from "components/BookingActions/OrderActions";
import { ClientInfoPopup } from "components/BookingInfoPopup";
import { config } from "config";
import { useTap } from "hooks/useTap";
import type { BookingSlot } from "models/booking.model";
import type { BookingOrderDTO } from "models/order.model";
import moment from "moment";
import type { MouseEvent } from "react";
import { useDrag } from "react-dnd";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Button, ICONS, Tag } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";
import { formatPhone } from "utils";

import { EDraggableEntity } from "../../constants";
import { StatusActions } from "./BaseStatusTag";
import { ExtraStatusTag } from "./ExtraStatusTag";
import { SlotCard } from "./SlotCard";
import styles from "./SlotCard.module.scss";
import { useExtendedStatuses } from "./hooks/useExtendedStatus";

export const OrderCard = ({ order }: { order: BookingOrderDTO }) => {
  return (
    <SlotCard.Root
      as="link"
      /* FIXME: изменить на относительный путь, когда сделаешь отдельные карточки для схемы зала */
      to={`${order.orderId}`}
      actions={
        <>
{/*           <OrderActions order={order} editUrl={`${order.orderId}/info?edit`} /> */}
          <ClientInfoPopup clientId={order.client.client_id} placement="auto">
            <Button variant="phantom" type="button" className={styles.userInfo}>
              <ICONS.Question />
            </Button>
          </ClientInfoPopup>
        </>
      }
    >
      <SlotCard.Content
        vip={order.client.vip}
        name={getFullName(
          order.client.name,
          order.client.middle_name,
          order.client.surname,
        )}
        nameAddon={order.client.gradeName}
        phone={formatPhone(order.client.phone)}
      />
      <SlotCard.AdditionalInfo
        date={order.bookingDate.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$3.$2.$1')}
        time={order.bookingTime}
        visitDuration={formatMinutesDuration(order.visitTime)}
        visitors={order.persons}
        tables={order.places.number}
        hallName={order.places.place.name}
        comment={order.comment}
      />
    </SlotCard.Root>
  );
};

export const DetailsBookingCard = ({
  booking,
  className,
}: {
  booking: BookingSlot;
  className?: string;
}) => {
  const { formatMessage } = useIntl();
  const { newStatus, visitProgress, visitTime } = useExtendedStatuses({
    status: booking.status,
    date: booking.date,
    time: booking.time,
    visitDuration: booking.visit_duration,
  });
  const isEndingSoon =
    moment(`${booking.date} ${booking.time}`, "YYYY-MM-DD HH:mm")
      .add(booking.visit_duration, "minutes")
      .diff(moment(), "minutes") <= 20;

  return (
    <SlotCard.Root
      as="div"
      className={className}
      statusClassName={newStatus.system_name.toLowerCase()}
      actions={
        <>
          {booking.status.system_name !== "WAIT_LIST" && (
            <BookingActions booking={booking} />
          )}
          {booking.client.contact_type === "CLIENT" && (
            <ClientInfoPopup
              clientId={booking.client.client_id}
              placement="auto"
            >
              <Button
                variant="phantom"
                type="button"
                className={styles.userInfo}
              >
                <ICONS.Question />
              </Button>
            </ClientInfoPopup>
          )}
        </>
      }
    >
      <SlotCard.Content
        vip={booking.client.vip}
        name={getFullName(
          booking.client.name,
          booking.client.middle_name,
          booking.client.surname,
        )}
        nameAddon={booking.client.gradeName}
        phone={formatPhone(booking.client.phone)}
        tags={booking.tags}
        visibleTagsCount={booking.client.phone ? 3 : 4}
      >
        <div className={styles.statusGroup}>
          {
            <StatusActions
              status={newStatus}
              extraStatus={booking.extra_status}
              bookingId={booking.slot_id}
              clientId={booking.client.client_id}
              statusClassName={cn(styles.statusTag, styles.baseStatusTag)}
            />
          }
          {booking.extra_status && (
            <ExtraStatusTag
              className={styles.statusTag}
              extraStatus={booking.extra_status}
            />
          )}
          {booking.seat_type === "MANAGER_BOOK" && (
            <Tag className={cn(styles.statusTag, styles.fromManagementTag)}>
              Manager
            </Tag>
          )}
          {booking.slot_deposit.use_deposit && <ICONS.DepositLock />}
          {isEndingSoon && <ICONS.ClockCircle />}
        </div>
      </SlotCard.Content>
      <SlotCard.AdditionalInfo
        seatType={booking.seat_type}
        visitProgress={visitProgress}
        time={booking.time}
        visitDuration={formatMinutesDuration(visitTime)}
        visitors={booking.visitors}
        tables={booking.slot_place.reduce(
          (result, place) => (result && `${result},\u00A0`) + place.table_name,
          "",
        )}
        hallName={booking.slot_place[0]?.hall_name}
        comment={booking.comment}
        warning={
          booking.overbooking
            ? formatMessage({ id: ETranslations.BOOKING_OVERLAP })
            : !booking.slot_place.length &&
                booking.status.system_name !== "WAIT_LIST"
              ? formatMessage({ id: ETranslations.BOOKING_NO_TABLE_SELECTED })
              : undefined
        }
      />
    </SlotCard.Root>
  );
};

export const HallBookingCard = ({ booking }: { booking: BookingSlot }) => {
  const isTablet = useIsTablet();
  const { formatMessage } = useIntl();
  const { newStatus, visitProgress, visitTime } = useExtendedStatuses({
    status: booking.status,
    date: booking.date,
    time: booking.time,
    visitDuration: booking.visit_duration,
  });
  const { handleTouchStart, handleTouchEnd, longTap } = useTap(100);
  const [isDragging, drag, dragPreview] = useDrag(
    () => ({
      type: EDraggableEntity.BOOKING_CARD,
      item: booking,
      canDrag: config.dragAndDrop && longTap,
      collect: (monitor) => monitor.isDragging(),
    }),
    [booking, longTap],
  );
  const handleContextMenuCapture = (e: MouseEvent) => {
    // Отменять контекстное меню необходимо для планшетов (и других touch устройств), чтобы long tap не отменял drag
    e.preventDefault();
    e.stopPropagation();
  };
  const isEndingSoon =
    moment(`${booking.date} ${booking.time}`, "YYYY-MM-DD HH:mm")
      .add(booking.visit_duration, "minutes")
      .diff(moment(), "minutes") <= 20;

  // Пустой input чтобы убрать дефолтный preview на десктопе
  return (
    <>
      <input ref={dragPreview} hidden />
      <SlotCard.Root
        as="link"
        statusClassName={newStatus.system_name.toLowerCase()}
        /* FIXME: изменить на относительный путь, когда сделаешь отдельные карточки для схемы зала */
        to={`booking/${booking.slot_id}`}
        onMouseDown={handleTouchStart}
        onMouseLeave={handleTouchEnd}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchEnd}
        onContextMenuCapture={handleContextMenuCapture}
        ref={drag}
        className={cn(styles.hallCard, { [styles.dragging]: isDragging })}
        actions={
          <>
            {booking.status.system_name !== "WAIT_LIST" && (
              <BookingActions
                allowMove
                booking={booking}
                editUrl={`booking/${booking.slot_id}/info?edit`}
              />
            )}
            {booking.client.contact_type === "CLIENT" && (
              <ClientInfoPopup
                clientId={booking.client.client_id}
                placement="auto"
              >
                <Button
                  variant="phantom"
                  type="button"
                  className={styles.userInfo}
                >
                  <ICONS.Question />
                </Button>
              </ClientInfoPopup>
            )}
          </>
        }
      >
        <SlotCard.Content
          vip={booking.client.vip}
          name={getFullName(
            booking.client.name,
            booking.client.middle_name,
            booking.client.surname,
          )}
          nameAddon={booking.client.gradeName}
          phone={formatPhone(booking.client.phone)}
          tags={booking.tags}
          visibleTagsCount={(isTablet ? 2 : 3) + (booking.client.phone ? 0 : 1)}
        >
          <div className={styles.statusGroup}>
            {
              <StatusActions
                status={newStatus}
                extraStatus={booking.extra_status}
                bookingId={booking.slot_id}
                clientId={booking.client.client_id}
                statusClassName={cn(styles.statusTag, styles.baseStatusTag)}
              />
            }
            {booking.extra_status && (
              <ExtraStatusTag
                className={styles.statusTag}
                extraStatus={booking.extra_status}
              />
            )}
            {booking.seat_type === "MANAGER_BOOK" && (
              <Tag className={cn(styles.statusTag, styles.fromManagementTag)}>
                Manager
              </Tag>
            )}
            {booking.slot_deposit.use_deposit && <ICONS.DepositLock />}
            {isEndingSoon && <ICONS.ClockCircle />}
          </div>
        </SlotCard.Content>
        <SlotCard.AdditionalInfo
          seatType={booking.seat_type}
          visitProgress={visitProgress}
          time={booking.time}
          visitDuration={formatMinutesDuration(visitTime)}
          visitors={booking.visitors}
          tables={booking.slot_place.reduce(
            (result, place) =>
              (result && `${result},\u00A0`) + place.table_name,
            "",
          )}
          hallName={booking.slot_place[0]?.hall_name}
          comment={booking.comment}
          warning={
            booking.overbooking
              ? formatMessage({ id: ETranslations.BOOKING_OVERLAP })
              : !booking.slot_place.length &&
                  booking.status.system_name !== "WAIT_LIST"
                ? formatMessage({ id: ETranslations.BOOKING_NO_TABLE_SELECTED })
                : undefined
          }
        />
      </SlotCard.Root>
    </>
  );
};
