import {
  useCreateBookingFormContext,
} from "containers/CreateBookingForm/Provider";
import type { RestaurantId } from "models/restaurant.model";
import { useRef } from "react";
import { DetailsCollapse } from "ui-kit/Collapse/Collapse";

import styles from "./RestaurantSelect.module.scss";

export const RestaurantSelect = () => {
  const { restaurant, restaurantsData, changeRestaurant } =
    useCreateBookingFormContext();
  const detailsRef = useRef<HTMLDetailsElement>(null);
  return (
    <form
      className={styles.restaurantSelectForm}
      onChange={(e) => {
        e.stopPropagation();
        if (e.target instanceof HTMLInputElement) {
          detailsRef.current && (detailsRef.current.open = false);
          const newRestaurant =
            restaurantsData.restaurantById[e.target.value as `${RestaurantId}`];
          newRestaurant && changeRestaurant(newRestaurant);
        }
      }}
    >
      <DetailsCollapse
        title={restaurant.name}
        className={styles.restaurantSelect}
        contentClassName={styles.restaurantOptions}
        icon={false}
        ref={detailsRef}
      >
        <ul>
          {restaurantsData.restaurants.map((r) => (
            <li key={r.id}>
              <input
                id={`restaurant-${r.id}`}
                value={r.id}
                readOnly
                checked={r.id === restaurant.id}
                type="radio"
                hidden
              />
              <label htmlFor={`restaurant-${r.id}`} className={styles.restaurantOption}>
                {r.name}
              </label>
            </li>
          ))}
        </ul>
      </DetailsCollapse>
    </form>
  );
};
