import cn from "classnames";
import { OrderActions } from "components/BookingActions/OrderActions";
import { BookingCardViewProps } from "components/BookingCard";
import { BookingCardNew } from "components/BookingCard/BookingCardNew";
import { OrderCard } from "components/BookingCard/OrderCard";
import type { BookingOrderDTO } from "models/order.model";
import { memo } from "react";
import type { BookingOrder } from "types/booking";
import { Spinner } from "ui-kit";

import { ETranslations } from "../../types/translates";
import styles from "./BookingList.module.scss";
import { useOrderList } from "./useOrderList";

export interface BookingsListProps<T extends BookingOrderDTO>
  extends BookingCardViewProps {
  orders: T[];
  className?: string;
  emptyMessage?: string;
  loading?: boolean;
  withActions?: boolean;
  allowMove?: boolean;
  canDrag?: boolean;
  onClick?: (book: BookingOrderDTO) => void;
  onEdit?: (order: BookingOrderDTO) => void;
}

export const OrderList = <T extends BookingOrderDTO>({
  orders,
  className,
  emptyMessage,
  loading,
  withActions,
  allowMove,
  canDrag = true,
  onClick,
  onEdit,
  ...props
}: BookingsListProps<T>) => {
  const { intl, onSelectBook, onEditBook, selectedBooking, isDashboard } =
    useOrderList();

  if (!orders.length) {
    return (
      <div className={cn(styles.list, className)}>
        <div className={styles.blank}>
          {emptyMessage ||
            intl.formatMessage({ id: ETranslations.BOOKING_LIST_EMPTY })}
          {loading && <Spinner className={styles.spinner} />}
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.list, className)}>
      {orders.map((order) => (
        <OrderCard key={order.orderId} order={order} />
      ))}
      {loading && <Spinner className={styles.spinner} />}
    </div>
  );
};
