import {
  ConfirmMessage,
  ConfirmMessageActions,
  ConfirmMessageHeader,
  ConfirmMessageText,
} from "components/ConfirmModal";
import { StepContainer } from "components/MultiStepForm";
import { useCreateBookingFormContext } from "containers/CreateBookingForm/Provider";
import { bookingFormLocalStore } from "containers/CreateBookingForm/localStore/bookingFormLocalStore";
import {
  formatClient,
  formatTables,
  formatTimeAndPersons,
} from "containers/CreateBookingForm/utils";
import dayjs from "dayjs";
import { useState } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import {
  Button,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogProvider,
  Spinner,
} from "ui-kit";
import { DataSection } from "ui-kit/DataSection/DataSection";

import styles from "./RestoreBookingModal.module.scss";

export const RestoreBookingModal = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const {
    cachedBookingData,
    cachedRestaurant,
    restaurant,
    updateBookingData,
    changeRestaurant,
  } = useCreateBookingFormContext();

  const [isModalOpen, setIsModalOpen] = useState(!!cachedBookingData);

  const { formatMessage } = useIntl();
  return isModalOpen ? (
    <StepContainer>
      <Spinner />
      <DialogProvider>
        <Dialog
          initialOpenModal
          onAfterClose={() => setIsModalOpen(false)}
          onCancel={() => {
            bookingFormLocalStore.removeItem("bookingData");
            bookingFormLocalStore.setItem("restaurant", restaurant);
          }}
        >
          <DialogCloseButton className={styles.close}/>
          <DialogContent
            onSubmit={() => {
              cachedRestaurant && changeRestaurant(cachedRestaurant);
              cachedBookingData && updateBookingData(cachedBookingData);
            }}
          >
            <ConfirmMessage variant="warning">
              <ConfirmMessageHeader>
                {formatMessage({
                  id: ETranslations.RESTORE_BOOKING_DATA_TITLE,
                })}
              </ConfirmMessageHeader>
              <ConfirmMessageText>
                {formatMessage({
                  id: ETranslations.RESTORE_BOOKING_DATA_TEXT,
                })}
              </ConfirmMessageText>
              <DataSection.Root
                className={styles.dataSection}
                title={formatMessage({ id: ETranslations.DETAILED_INFO })}
                dataListClassName={styles.dataList}
              >
                {cachedRestaurant && (
                  <DataSection.Item
                    label={formatMessage({
                      id: ETranslations.RESTAURANT_NAME,
                    })}
                  >
                    {cachedRestaurant.name}
                  </DataSection.Item>
                )}
                {cachedBookingData?.bookingDate && (
                  <DataSection.Item
                    label={formatMessage({
                      id: ETranslations.BASE_DATE,
                    })}
                  >
                    {dayjs(cachedBookingData.bookingDate).format(
                      "DD MMMM, dddd",
                    )}
                  </DataSection.Item>
                )}
                {cachedBookingData?.persons &&
                  cachedBookingData?.bookingTime && (
                    <DataSection.Item
                      label={formatMessage({
                        id: ETranslations.NUMBER_OF_GUESTS_TITLE,
                      })}
                    >
                      {formatTimeAndPersons({
                        time: cachedBookingData.bookingTime,
                        persons: cachedBookingData.persons,
                        formatMessage,
                      })}
                    </DataSection.Item>
                  )}
                {cachedBookingData?.client && (
                  <DataSection.Item
                    label={formatMessage({
                      id: ETranslations.PLURAL_GUEST,
                    })}
                  >
                    {formatClient(cachedBookingData.client)}
                  </DataSection.Item>
                )}
                {cachedBookingData?.tables && cachedRestaurant && (
                  <DataSection.Item
                    label={formatMessage({
                      id: ETranslations.PLURAL_TABLE,
                    })}
                  >
                    {formatTables({
                      tables: cachedBookingData.tables,
                      placeById: cachedRestaurant.placeById,
                      formatMessage,
                    })}
                  </DataSection.Item>
                )}
              </DataSection.Root>
              <ConfirmMessageActions className={styles.actions}>
                <Button variant="primary" type="submit">
                  {formatMessage({
                    id: ETranslations.BASE_CONFIRM,
                  })}
                </Button>
                <DialogCloseButton isCancelButton variant="secondary">
                  {formatMessage({ id: ETranslations.BASE_CANCEL })}
                </DialogCloseButton>
              </ConfirmMessageActions>
            </ConfirmMessage>
          </DialogContent>
        </Dialog>
      </DialogProvider>
    </StepContainer>
  ) : (
    children
  );
};
