import type { DeepPartial } from "@reduxjs/toolkit";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import cn from "classnames";
import { BookingCard } from "components/BookingCard";
import { OrderCard } from "components/BookingCard/OrderCard";
import { ClientList } from "containers/Guests/Clients/ClientList";
import { CloseBookingContext } from "contexts/CloseBookingContext";
import type { FormBooking } from "features/BookingFormProxy/types";
import { useGetOrderQuery } from "features/api/booking-order-api";
import { useIntlUtils } from "hooks/useIntlUtils";
import type { OrderSlot } from "models/booking.model";
import React from "react";
import { useIntl } from "react-intl";
import { Outlet, useParams } from "react-router-dom";
import { Params } from "types/commons";
import { Card, ICONS, Spinner } from "ui-kit";
import { useIsTablet } from "ui-kit/hooks";

import { HideWhen } from "../../components/HideWhen";
import { HallSchema } from "../../components/hall-scheme/redux/HallSchemaV2/hall-schema";
import BookingFormReduxProxy from "../../components/registration/forms/BookingFormReduxProxy";
import { CreateFromOrder } from "../../components/registration/forms/create-from-order";
import { HallMode } from "../../features/HallSchema";
import { ETranslations } from "../../types/translates";
import styles from "../Dashboard/Dashboard.module.scss";
import { BookingOrdersList } from "./BookingOrdersList";
import useBookingOrders from "./hooks/useBookingOrders";
import useEditOrder from "./hooks/useEditOrder";

export const EmptyOrder = () => {
  const { getIntlEntityViewingAction } = useIntlUtils();
  const isTablet = useIsTablet();
  return !isTablet ? (
    <section className={styles.noDetails}>
      <ICONS.Requests width={220} height={220} />
      <p>
        {getIntlEntityViewingAction(
          ETranslations.OF_REQUEST,
          ETranslations.PLURAL_REQUESTS_THEY,
        )}
      </p>
    </section>
  ) : null;
};

export const EditOrder = () => {
  const { orderId } = useParams<Params>();
  const { formatMessage, locale } = useIntl();
  const { update } = useEditOrder();
  const { data, isLoading } = useGetOrderQuery(orderId ?? skipToken);
  const formData: DeepPartial<FormBooking> = {
    client: data?.client,
    booking_date: data?.bookingDate,
    booking_time: data?.bookingTime,
    client_id: data?.client.client_id,
    comment: data?.comment,
    persons: data?.persons,
    phone: data?.client.phone,
    restaurant_id: data?.restaurantId,
    placeId: data?.places.placeId,
    visit_time: data?.visitTime,
    deposit: data?.useDeposit ? { use_deposit: data?.useDeposit } : undefined,
    tables: [data?.places.id],
  };

  return data ? (
    <Card className={styles.edit}>
      <Card.NavLinkTabs
        links={[
          {
            title: formatMessage(
              { id: ETranslations.ENTITY_CONFIRMING },
              {
                entity: formatMessage({
                  id:
                    locale === "en"
                      ? ETranslations.PLURAL_REQUEST
                      : ETranslations.PLURAL_REQUESTS_NOM,
                }).toLowerCase(),
              },
            ),
            to: ".",
          },
        ]}
        navigateOnClose=".."
      />
      <div className={cn(styles.editCard)}>
        <OrderCard key={data.orderId} order={data} />
      </div>
      <BookingFormReduxProxy
        order={data}
        initialFormValues={formData}
        submitFn={(values: any) => update({ ...values, orderId: data.orderId })}
      />
    </Card>
  ) : isLoading ? (
    <Spinner />
  ) : null;
};

export const BookingOrders: React.FC<any> = () => {
  const {
    intl,
    isTablet,
    hasOrder,
    onSelectOrder,
    handleClose,
    mode,
    orderSelected,
    getIntlEntityViewingAction,
    closeGuests,
    handleSetClient,
    closeHall,
  } = useBookingOrders();

  return (
    <React.Suspense fallback={<Spinner />}>
      <BookingOrdersList onSelectOrder={onSelectOrder} />
      <Outlet />
    </React.Suspense>
    /*  <CloseBookingContext.Provider value={handleClose}>
        <HideWhen
          condition={
            (isTablet && !hasOrder) ||
            mode.includes("HALL") ||
            [
              HallMode.BOOKING_GUEST,
              HallMode.TABLE_BOOKINGS_EDIT_GUEST,
            ].includes(mode)
          }
          noUnmount
        >
          <Card onClose={handleClose}>
            <Card.Header title="Подтверждение" />
            <Card.Content noPadding>
              {hasOrder ? (
                <EditOrder orderSelected={orderSelected} />
              ) : (
                <EmptyDetails/>
              )}
            </Card.Content>
          </Card> */
  );
};
