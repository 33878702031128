import { DAYS } from "types/commons";
import { z } from "zod";

import { PageableScheme } from "./common";

export const DailyProgramId = z.number().int().brand("DailyProgramId");
export type DailyProgramId = z.infer<typeof DailyProgramId>;

export const DailyProgramDTO = z.object({
  name: z.string(),
  description: z.string().optional(),
  id: DailyProgramId,
  start_date: z.string().date(),
  end_date: z.string().date(),
  start_time: z.string().time(),
  end_time: z.string().time(),
  repeat_days: z.enum(DAYS).array(),
});
export type DailyProgramDTO = z.infer<typeof DailyProgramDTO>;

export const DailyProgramsSearchDTO = z.object({
  content: DailyProgramDTO.array(),
  ...PageableScheme,
});
export type DailyProgramsSearchDTO = z.infer<typeof DailyProgramsSearchDTO>;
