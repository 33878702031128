import dayjs from "dayjs";
import { RestaurantId } from "models/restaurant.model";
import { DAYS } from "types/commons";
import { ETranslations } from "types/translates";
import { z } from "zod";

const DatePickerDate = z
  .string()
  .transform((val) => dayjs(val, "ddd, DD.MM.YYYY"))
  .refine((val) => val.isValid(), ETranslations.FIELD_INVALID)
  .transform((val) => val.format("YYYY-MM-DD"));

export const ProgramFormSchema = z.object({
  restaurantId: z.coerce.number().pipe(RestaurantId),
  programName: z
    .string()
    .trim()
    .min(1, { message: ETranslations.FIELD_CANT_BE_EMPTY })
    .max(100, { message: ETranslations.FIELD_TOO_LONG }),
  weekdays: z.union([
    z.enum(DAYS).transform((val) => [val]),
    z.enum(DAYS).array(),
  ]),
  description: z
    .string()
    .trim()
    .min(1, { message: ETranslations.FIELD_CANT_BE_EMPTY }),
  startDate: DatePickerDate,
  endDate: DatePickerDate.optional(),
  isIndefinitely: z.literal("on").transform(Boolean).optional(),
});
export type ProgramFormSchemaInput = z.input<typeof ProgramFormSchema>;
export type ProgramFormSchema = z.output<typeof ProgramFormSchema>;
