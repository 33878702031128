import { ChangeEventHandler, Ref, forwardRef } from "react";
import OriginalPhoneInput, { type Value } from "react-phone-number-input/input";



import { Input, InputProps } from "./Input";


const InputOverride = forwardRef(
  ({ onChange, ...props }: InputProps, ref: Ref<HTMLInputElement>) => {
    const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      if ((e.target.value.match(/\d/g)?.length ?? 0) > 15) return;
      onChange?.(e);
    };

    return <Input {...props} ref={ref} onChange={handleOnChange} />;
  },
) as typeof Input;

interface PhoneInputProps extends Omit<InputProps, "value" | "onChange"> {
  onChange: (val: Value) => void;
  value: Value;
}

export function PhoneInput(props: PhoneInputProps) {
  return (
    <OriginalPhoneInput
      placeholder="+"
      {...props}
      // @ts-ignore
      inputComponent={InputOverride}
    />
  );
}