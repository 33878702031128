import {
  AccessRuleGroup,
  type AccessRuleGroupCreationPayload,
  type AccessRuleGroupId,
  WidgetConfig,
} from "models/accessRules.model";
import type { RestaurantId } from "models/restaurant.model";

import { IResponse } from "../../models/common";
import { RequiredBy } from "./../../types/commons";
import { coreApi2 } from "./bookings-api/core";

export const accessRulesApi = coreApi2
  .enhanceEndpoints({ addTagTypes: ["Rules"] })
  .injectEndpoints({
    endpoints: (build) => ({
      createAccessRule: build.mutation<
        AccessRuleGroup,
        AccessRuleGroupCreationPayload
      >({
        query: (payload) => ({
          url: "access",
          method: "POST",
          body: payload,
        }),
        invalidatesTags: [{ type: "AccessRules", id: "LIST" }],
      }),

      getAccessRules: build.query<AccessRuleGroup[], RestaurantId>({
        query: (restaurantId) => ({
          url: `access/${restaurantId}/list`,
          method: "GET",
        }),
        providesTags: () => [{ type: "AccessRules", id: "LIST" }],
        transformResponse: (response: IResponse<AccessRuleGroup[]>) => {
          const result = AccessRuleGroup.array().safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
      }),

      removeAccessRule: build.mutation<unknown, AccessRuleGroupId>({
        query: (id) => ({
          url: `access/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: [{ type: "AccessRules", id: "LIST" }],
      }),

      editAccessRule: build.mutation<
        AccessRuleGroup,
        RequiredBy<AccessRuleGroup, "id">
      >({
        query: (payload) => ({
          url: `access/${payload.id}`,
          method: "PUT",
          body: payload,
        }),
        invalidatesTags: () => [{ type: "AccessRules", id: "LIST" }],
      }),

      getAccessRule: build.query<
        AccessRuleGroup & { widget: WidgetConfig },
        AccessRuleGroupId
      >({
        query: (id) => ({
          url: `access/${id}`,
          method: "GET",
        }),
        transformResponse: (
          response: IResponse<AccessRuleGroup & { widget: WidgetConfig }>,
        ) => {
          const result = AccessRuleGroup.extend({
            widget: WidgetConfig,
          }).safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
      }),
    }),
  });

export const {
  useCreateAccessRuleMutation,
  useRemoveAccessRuleMutation,
  useGetAccessRulesQuery,
  useEditAccessRuleMutation,
  useGetAccessRuleQuery,
} = accessRulesApi;
