import { Outlet } from "react-router-dom";

import { CreateBookingFormHeader } from "./CreateBookingFormHeader";
import { CreateBookingFormStepper } from "./CreateBookingFormStepper";
import { RestoreBookingModal } from "./RestoreBookingModal";

export const CreateBookingFormLayout = () => {
  return (
    <>
      <CreateBookingFormHeader />
      <CreateBookingFormStepper />
      <RestoreBookingModal>
        <Outlet />
      </RestoreBookingModal>
    </>
  );
};
