import cn from "classnames";
import { useConfig } from "features/api/dictionaries-api";
import type { PermissionName } from "models/user.model";
import { type FunctionComponent, type SVGProps } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { useCheckPermission } from "services/permissionChecker";
import { ETranslations } from "types/translates";
import {
  Dashboard,
  Dialogs,
  Floorplan,
  Grid,
  Guests,
  Requests,
  Schedule,
  Settings,
} from "ui-kit/ICONS/icons";

import styles from "./NavLinks.module.scss";
import { OrderRequestCount, UnansweredDialogCount } from "./SidebarBadges";

export const NavLinks = () => {
  const { formatMessage } = useIntl();
  const { hasPermissionFor } = useCheckPermission();
  const {config} = useConfig();

  const links: {
    to: string;
    Icon: FunctionComponent<
      SVGProps<SVGSVGElement> & { title?: string | undefined }
    >;
    text: ETranslations;
    hide?: boolean;
    permission: PermissionName;
    Badge?: () => JSX.Element | null;
  }[] = [
    {
      to: "dashboard",
      Icon: Dashboard,
      text: ETranslations.SIDEBAR_DASHBOARD,
      permission: "SECTION_BOOKINGS",
    },
    {
      to: "hall-scheme",
      Icon: Floorplan,
      text: ETranslations.HALL_SCHEME,
      permission: "SECTION_HALL_SCHEMA",
    },
    {
      to: "requests",
      Icon: Requests,
      text: ETranslations.PLURAL_REQUESTS_NOM,
      hide: !config.hasRequests,
      permission: "SECTION_ORDER",
      Badge: OrderRequestCount,
    },
    {
      to: "guests",
      Icon: Guests,
      text: ETranslations.GUEST_LIST,
      permission: "SECTION_GUESTS",
    },
    {
      to: "dialogs",
      Icon: Dialogs,
      text: ETranslations.SIDEBAR_DIALOGS,
      permission: "SECTION_DIALOGS",
      Badge: UnansweredDialogCount,
    },
    {
      to: "grid",
      Icon: Grid,
      text: ETranslations.SIDEBAR_GRID,
      permission: "SECTION_BOOKINGS",
      hide: !config.requestGrid,
    },
    {
      to: "schedule-landing",
      Icon: Schedule,
      text: ETranslations.SIDEBAR_BOARDING_SCHEDULE,
      permission: "SECTION_LANDING_SCHEDULE",
    },
    {
      to: "settings",
      Icon: Settings,
      text: ETranslations.SIDEBAR_HANDLING,
      permission: "SECTION_MANAGEMENT",
    },
  ];
  return (
    <nav className={styles.nav}>
      <ul className={styles.linkList}>
        {links.reduce<JSX.Element[]>(
          (result, { to, Icon, text, hide, permission, Badge }) => (
            !hide &&
              hasPermissionFor(permission) &&
              result.push(
                <li key={text}>
                  <NavLink
                    className={({ isActive, isPending }) =>
                      cn(
                        styles.link,
                        isActive && styles.active,
                        isPending && styles.pending,
                      )
                    }
                    to={to}
                  >
                    <Icon className={styles.icon} width={34} height={34} />
                    {formatMessage({ id: text })}
                    {Badge && <Badge />}
                  </NavLink>
                </li>,
              ),
            result
          ),
          [],
        )}
      </ul>
    </nav>
  );
};
