import { M, predata } from "common/helpers";
import { BookingsHistory } from "components/BookingsHistory";
import { RestaurantManagement } from "components/RestaurantManagement";
import { Shifts } from "components/shifts";
import { Booking } from "models/booking.model";
import { OptionSettingsMode, SettingsModes } from "models/settings.model";
import { AppointUser } from "models/user.model";
import { Outlet } from "react-router-dom";

import { ETranslations } from "../../types/translates";
import type { Settings } from "./settings";
import AccessRules from "./sub-renders/access_rules";
import AppointHostess from "./sub-renders/appoint-hostess";
import { StatusManagement } from "./sub-renders/status-management/status-management";
import TagsPage from "./sub-renders/tags";

/* eslint-disable */
export const getOptionsSettingsMode = (): OptionSettingsMode[] => [
  {
    value: 6,
    label: ETranslations.SETTINGS_TITLE_SHIFTS,
    mode: SettingsModes.shifts,
    permission: "FEATURE_MANAGEMENT_SHIFT_VIEW",
  },
  {
    value: 1,
    label: ETranslations.SETTINGS_TITLE_ROLES,
    mode: SettingsModes.appointRoles,
    permission: "SECTION_MANAGEMENT",
  },
  {
    value: 5,
    label: ETranslations.SETTINGS_TITLE_BOOKING_HISTORY,
    mode: SettingsModes.changeBook,
    permission: "SECTION_MANAGEMENT",
  },
  {
    value: 6,
    label: ETranslations.SETTINGS_TITLE_RESTAURANT_MANAGEMENT,
    mode: SettingsModes.restManagement,
    permission: "FEATURE_MANAGEMENT_RESTAURANT",
  },
  {
    value: 7,
    label: ETranslations.ACCESS_RULES,
    mode: SettingsModes.access_rules,
    permission: "SECTION_MANAGEMENT",
  },
  {
    value: 8,
    label: ETranslations.STATUSES,
    mode: SettingsModes.statuses,
    permission: "FEATURE_MANAGEMENT_STATUSES",
  },
  {
    value: 9,
    label: ETranslations.TAGS,
    mode: SettingsModes.tags,
    permission: "FEATURE_MANAGEMENT_TAG",
  },
  {
    value: 10,
    label: ETranslations.PROGRAMS,
    mode: SettingsModes.programs,
    permission: "FEATURE_MANAGEMENT_TAG",
  },
];

/* eslint-enable */

export const isBookingsDateEqual = (
  prevBook: Booking | undefined,
  { date }: Booking,
) => {
  if (!prevBook) {
    return false;
  }
  return M(prevBook.date).isSame(date, "d");
};

export const getRestName = ({ restaurant_id: id, role, name }: AppointUser) => {
  if (typeof id !== "number" && role === "ADMINISTRATOR") {
    return ETranslations.ALL_RESTAURANTS;
  }
  if (typeof id !== "number") {
    return ETranslations.UNKNOWN_RESTAURANT;
  }
  if (role === "RESTAURANT") {
    return name;
  }
  return predata.restsMapper[id]?.label;
};

export const renderCardContent = (settings: Settings) => {
  const {
    props: {
      onCallModalHostess,
      onCallModalConfirm,
      onCallModalUser,
      onCallModalStatus,
      intlUtils,
    },
    state: {
      selectedMode,
      restaurantList,
      isLoading,
      selectedSectionId,
      endDate,
      clientName,
      userName,
      hostess,
    },
  } = settings;
  switch (selectedMode.mode) {
    case SettingsModes.sources:
      return (
        <div>
          {intlUtils.intl.formatMessage({
            id: ETranslations.SOURCES,
          })}
        </div>
      );
    case SettingsModes.newRest:
      return (
        <div>
          {intlUtils.intl.formatMessage({
            id: ETranslations.STARTING_NEW_RESTAURANT,
          })}
        </div>
      );
    case SettingsModes.appointHostess:
      return (
        <AppointHostess
          isLoading={isLoading}
          restaurantList={restaurantList}
          selectedSectionId={selectedSectionId}
          setRestaurant={(restId) => settings.setRestaurant(restId)}
          onCallModalConfirm={(id, mode) => onCallModalConfirm(id, mode)}
          onCallModalHostess={(hostessUser, restId) =>
            onCallModalHostess(hostessUser, restId)
          }
        />
      );
    case SettingsModes.appointRoles:
    case SettingsModes.programs:
      return <Outlet />;
    case SettingsModes.access_rules:
      return <AccessRules isLoading={isLoading} />;
    case SettingsModes.tags:
      return <TagsPage />;
    case SettingsModes.changeBook:
      return (
        <BookingsHistory
          dateFrom={endDate}
          dateTo={endDate}
          searchWords={clientName}
          userid={hostess?.value}
        />
      );
    case SettingsModes.shifts:
      return <Shifts />;
    case SettingsModes.restManagement:
      return <RestaurantManagement />;
    case SettingsModes.statuses:
      return (
        <StatusManagement
          onCallModalStatus={(allStatuses, statusId, editStatus, isEditing) =>
            onCallModalStatus(allStatuses, statusId, editStatus, isEditing)
          }
        />
      );
    default:
      return null;
  }
};

export const isWithoutSearchBlock = (selectedMode: SettingsModes) =>
  [
    SettingsModes.shifts,
    SettingsModes.restManagement,
    SettingsModes.access_rules,
    SettingsModes.statuses,
    SettingsModes.tags,
    SettingsModes.appointRoles,
    SettingsModes.programs
  ].includes(selectedMode);
