import dayjs from "dayjs";
import type { DateISO, MonthISO } from "types/commons";
import { renderWeekdays } from "utils";

import styles from "./SmallCalendar.module.scss";

const getDateISO = (formattedDate: `${number}-${MonthISO}-`, day: number) =>
  (formattedDate + `${day}`.padStart(2, "0")) as DateISO;

export const SmallCalendar = ({
  name,
  defaultDateISO,
  year,
  month,
  enabledDaysSet,
}: {
  name: string;
  defaultDateISO: DateISO;
  year: number;
  month: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | (number & {});
  enabledDaysSet: Set<DateISO>;
}) => {
  const weekStartsFrom = dayjs.localeData().firstDayOfWeek();
  const date = dayjs({ year, month }).utc(true);
  const formattedDate = date.format("YYYY-MM-") as `${number}-${MonthISO}-`;

  return (
    <article className={styles.calendar}>
      <ol className={styles.weekdays}>
        {renderWeekdays((weekday) => (
          <li key={weekday}>{weekday}</li>
        ))}
      </ol>
      <ol
        className={styles.days}
        style={{
          "--first-weekday": date.day() + 1 - weekStartsFrom || 7,
        }}
      >
        {Array.from({ length: date.daysInMonth() }, (_, dayIndex) => {
          const dateISO = getDateISO(formattedDate, dayIndex + 1);
          return (
            <li key={dateISO}>
              <input
                id={dateISO}
                type="radio"
                hidden
                name={name}
                value={dateISO}
                disabled={!enabledDaysSet.has(dateISO)}
                defaultChecked={dateISO === defaultDateISO}
              />
              <label className={styles.label} htmlFor={dateISO} />
            </li>
          );
        })}
      </ol>
    </article>
  );
};
