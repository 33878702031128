import cn from "classnames";
import {
  ConfirmDialog,
  type ConfirmDialogProps,
} from "components/ConfirmModal";
import { useFetchRestaurantNotesQuery } from "features/api/notes-api";
import type { RestaurantId } from "models/restaurant.model";
import { type ReactNode, useState } from "react";
import { Button, ICONS } from "ui-kit";

import styles from "./NoteButton.module.scss";
import { NotesDialog } from "./NotesDialog";

export const NotesButton = ({
  restaurantId,
  date,
  children,
  containerClassName,
  className,
}: {
  restaurantId: RestaurantId;
  date: string;
  children?: ReactNode;
  containerClassName?: string;
  className?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmModalProps, setConfirmModalProps] =
    useState<ConfirmDialogProps | null>(null);
  const { data, isLoading } = useFetchRestaurantNotesQuery({
    restaurantId,
    date,
  });
  return (
    <span className={containerClassName}>
      <Button
        className={cn(styles.button, className)}
        variant="primary"
        onClick={() => setIsOpen(true)}
        disabled={isLoading}
      >
        {children || (data ? <ICONS.ExistingNote /> : <ICONS.CreateNote />)}
      </Button>
      {isOpen && (
        <NotesDialog
          restaurantId={restaurantId}
          date={date}
          showConfirmDialog={setConfirmModalProps}
          onAfterClose={() => setIsOpen(false)}
        />
      )}
      {confirmModalProps && (
        <ConfirmDialog {...confirmModalProps} transitionDuration={0} />
      )}
    </span>
  );
};
