import { useCreateDailyProgramMutation } from "features/api/daily-program-api";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";
import {
  Button,
  Card,
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogProvider,
} from "ui-kit";
import { commonFormErrorMap, formDataToObject } from "utils";
import type { typeToFlattenedError } from "zod";

import { MAX_DATE } from "../../../../../../../constants";
import { ProgramFormSchema, type ProgramFormSchemaInput } from "../../model";
import { ProgramForm } from "../ProgramForm/ProgramForm";
import styles from "./CreateProgramModal.module.scss";

export const CreateProgramFooter = ({ disabled }: { disabled?: boolean }) => {
  const { formatMessage } = useIntl();
  return (
    <footer className={styles.footer}>
      <DialogCloseButton variant="secondary" disabled={disabled}>
        {formatMessage({ id: ETranslations.BASE_CANCEL })}
      </DialogCloseButton>
      <Button type="submit" variant="primary" disabled={disabled}>
        {formatMessage({ id: ETranslations.BASE_SAVE })}
      </Button>
    </footer>
  );
};

export const CreateProgramModal = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [create, { isLoading }] = useCreateDailyProgramMutation();
  const [errors, setErrors] =
    useState<typeToFlattenedError<ProgramFormSchemaInput>>();
  return (
    <DialogProvider>
      <Dialog
        initialOpenModal
        onAfterClose={() => navigate('/settings/programs')}
        className={styles.dialog}
        position="left"
      >
        <DialogContent
          className={styles.dialogContent}
          onSubmit={(e) => {
            const formData = new FormData(e.currentTarget);
            const formObj = formDataToObject(formData);
            const result = ProgramFormSchema.safeParse(formObj, {
              errorMap: commonFormErrorMap,
            });
            if (!result.success) {
              e.preventDefault();
              return setErrors(result.error.flatten());
            }
            create({
              restaurantId: result.data.restaurantId,
              name: result.data.programName,
              description: result.data.description,
              repeat_days: result.data.weekdays,
              start_date: result.data.startDate,
              start_time: "00:00",
              end_date:
                result.data.isIndefinitely || !result.data.endDate
                  ? MAX_DATE
                  : result.data.endDate,
              end_time: "23:59",
            });
          }}
        >
          <Card as="section">
            <Card.Header
              title={formatMessage(
                { id: ETranslations.ENTITY_CREATING },
                {
                  entity: formatMessage({
                    id: ETranslations.PROGRAM_ALT_2,
                  }).toLowerCase(),
                },
              )}
              controls={<DialogCloseButton />}
            />
            <ProgramForm errors={errors} />
            <CreateProgramFooter disabled={isLoading} />
          </Card>
        </DialogContent>
      </Dialog>
    </DialogProvider>
  );
};
