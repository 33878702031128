import cn from "classnames";
import {
  type ReactNode,
  TextareaHTMLAttributes,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { FieldError } from "ui-kit/FieldError";

import { Labeled } from "../Labeled";
import styles from "./Textarea.module.scss";
import {
  TextAreaContextProvider,
  useTextAreaContext,
  useTextAreaContextSelector,
} from "./context";

// Функция для вычисления процента для управления цветом, начинаем изменять цвет с colorChangeThreshold % от maxLength
const getAdjustedColorPercentage = (
  textLength: number,
  maxLength: number,
  colorChangeThreshold: number,
) =>
  (
    Math.max(
      Math.min((textLength * 100) / maxLength, 100) - colorChangeThreshold,
      0,
    ) *
    (100 / (100 - colorChangeThreshold))
  ).toFixed(2);

type BaseTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export interface TextareaProps extends BaseTextareaProps {
  label?: string;
}

export function Textarea({ label, className, ...props }: TextareaProps) {
  return (
    <Labeled className={className} label={label} required={props.required}>
      <textarea {...props} className={styles.textarea} />
    </Labeled>
  );
}

const AutoResizeTextareaRoot = ({
  label,
  name,
  maxLength,
  defaultValue,
  className,
  required,
  children,
  error,
}: {
  label?: ReactNode;
  name?: string;
  maxLength: number;
  defaultValue?: string;
  className?: string;
  required?: boolean;
  children: ReactNode;
  error?: string | string[];
}) => {
  const [counter, setCounter] = useState(defaultValue?.length || 0);

  const value = {
    name,
    defaultValue,
    maxLength,
    counter,
    setCounter,
  };

  return (
    <label
      className={cn(styles.container, required && styles.required, className)}
    >
      <span>{label}</span>
      <TextAreaContextProvider value={value}>
        {children}
      </TextAreaContextProvider>
      {error && (
        <FieldError
          error={error}
          fieldName={typeof label === "string" ? label : name}
          value={maxLength}
        />
      )}
    </label>
  );
};

const AutoResizeTextareaInput = forwardRef<
  HTMLTextAreaElement,
  Omit<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    "maxLength" | "defaultValue" | "name"
  >
>(({ className, onChange, ...props }, ref) => {
  const name = useTextAreaContextSelector((c) => c.name);
  const maxLength = useTextAreaContextSelector((c) => c.maxLength);
  const defaultValue = useTextAreaContextSelector((c) => c.defaultValue);
  const setCounter = useTextAreaContextSelector((c) => c.setCounter);

  return (
    <textarea
      className={cn(styles.textarea, styles.autoResize, className)}
      name={name}
      ref={ref}
      defaultValue={defaultValue}
      maxLength={maxLength}
      onChange={(e) => {
        setCounter(e.currentTarget.value.length);
        onChange?.(e);
      }}
      {...props}
    />
  );
});

AutoResizeTextareaInput.displayName = "AutoResizeTextareaInput";

const AutoResizeTextareaCounter = ({ className }: { className?: string }) => {
  const { maxLength, counter } = useTextAreaContext();
  return (
    <data
      style={{
        "--text-length-percentage": `${getAdjustedColorPercentage(counter, maxLength, 70)}%`,
      }}
      value={maxLength}
      className={cn(styles.counter, className)}
    >
      {counter}
    </data>
  );
};

export const TextareaComposite = {
  Root: AutoResizeTextareaRoot,
  Input: AutoResizeTextareaInput,
  Counter: AutoResizeTextareaCounter,
};
