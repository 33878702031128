import { getFullName, getMaskPhone } from "common/helpers";
import { SourceContactInput } from "components/SourceInput";
import {
  SOURCE_TYPES,
  type SourceContactId,
  type SourceType,
} from "models/source.model";
import { type ReactNode, useState } from "react";
import {
  type Control,
  Controller,
  useController,
  useFormContext,
} from "react-hook-form";
import { useIntl } from "react-intl";
import { type Value as E164Number } from "react-phone-number-input";
import { ETranslations } from "types/translates";
import { Button, Input, SelectBasic } from "ui-kit";
import { TrashBin, USER_PHONE } from "ui-kit/ICONS/icons";

import type {
  SourceFormSchema,
  SourceFormSchemaInput,
} from "../../model/schema";
import styles from "./SourceForm.module.scss";

export const SourceContactFields = () => {
  const [existedContact, setExistedContact] = useState<{
    id: SourceContactId;
    phone?: string;
    fullName?: string;
  }>();
  const { formatMessage } = useIntl();
  const { register, control, formState, setValue } = useFormContext<
    SourceFormSchemaInput,
    SourceFormSchema
  >();
  return (
    <details className={styles.details}>
      <summary>{formatMessage({ id: ETranslations.CONTACT })}</summary>
      <input
        type="hidden"
        {...register("sourceContactId", { required: false })}
      />
      <Controller
        control={control}
        name="sourceContactId"
        render={({ field: { onChange, value } }) => (
          <>
            <span className={styles.contactSearchBox}>
              <SourceContactInput
                placeholder={formatMessage({
                  id: ETranslations.CONTACT_SEARCH,
                })}
                sourceContactId={
                  value !== "" ? (Number(value) as SourceContactId) : undefined
                }
                onSelect={(sourceContact) => {
                  if (!sourceContact) {
                    onChange("");
                  } else {
                    onChange(sourceContact.id.toString());
                    setExistedContact({
                      id: sourceContact.id,
                      phone: sourceContact.phone
                        ? getMaskPhone(sourceContact.phone)
                        : undefined,
                      fullName: getFullName(
                        sourceContact.surname,
                        sourceContact.name,
                        sourceContact.middle_name,
                      ),
                    });
                  }
                }}
                onReset={() => {
                  setExistedContact(undefined);
                  onChange("");
                }}
              />
              <Button
                className={styles.addNewButton}
                onClick={() => {
                  onChange("");
                  setExistedContact(undefined);
                }}
                variant="thin"
                disabled={false}
              >
                +&nbsp;
                {formatMessage(
                  { id: ETranslations.BASE_ADD_ENTITY },
                  {
                    entity: formatMessage(
                      { id: ETranslations.NEW_UNIT },
                      { unit: "" },
                    ),
                  },
                )}
              </Button>
            </span>
            {existedContact && (
              <span className={styles.selected}>
                <span className={styles.phone}>
                  <USER_PHONE />
                  {existedContact.phone || "N/A"}
                </span>
                <span>{existedContact.fullName || "N/A"}</span>
                <button
                  className={styles.resetButton}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setExistedContact(undefined);
                    onChange("");
                  }}
                >
                  <TrashBin />
                </button>
              </span>
            )}
          </>
        )}
      />

      {!existedContact && (
        <fieldset className={styles.newContactFields}>
          <Input
            label={formatMessage({ id: ETranslations.LAST_NAME })}
            placeholder={formatMessage(
              { id: ETranslations.BASE_ENTER_VALUE },
              { value: formatMessage({ id: ETranslations.LAST_NAME_ALT }) },
            )}
            autoComplete="off"
            {...register("surname", { required: false })}
            error={formState.errors.surname?.message}
          />
          <Input
            label={formatMessage({ id: ETranslations.NAME })}
            autoComplete="off"
            required
            {...register("name")}
            error={formState.errors.name?.message}
          />
          <Input
            label={formatMessage({ id: ETranslations.MIDDLE_NAME })}
            autoComplete="off"
            {...register("middleName", { required: false })}
            error={formState.errors.middleName?.message}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value } }) => (
              <Input.Phone
                label={formatMessage({ id: ETranslations.PHONE })}
                autoComplete="off"
                required
                value={value as E164Number}
                onChange={(v) => onChange(v || "")}
                error={formState.errors.phone?.message}
              />
            )}
          />
          <Input
            label={formatMessage({ id: ETranslations.EXTENSION_PHONE })}
            type="tel"
            autoComplete="off"
            {...register("additionalPhone", { required: false })}
            error={formState.errors.additionalPhone?.message}
          />
          <Input
            label={formatMessage({ id: ETranslations.EMAIL })}
            type="email"
            autoComplete="off"
            {...register("email", { required: false })}
            error={formState.errors.email?.message}
          />
        </fieldset>
      )}
    </details>
  );
};
