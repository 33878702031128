import cn from "classnames";
import { type InputHTMLAttributes } from "react";
import type { Merge } from "types/commons";



import styles from "./checkbox.module.scss";


export default function Checkbox({
  text,
  onChange,
  value,
  disabled,
  className,
  useStopPropagation,
  indent,
  ...inputProps
}: Merge<
  InputHTMLAttributes<HTMLInputElement>,
  {
    indent?: string;
    text?: string;
    onChange?: (e: boolean) => void;
    value?: boolean;
    disabled?: boolean;
    className?: string;
    useStopPropagation?: boolean;
  }
>) {
  return (
    <label data-text={text} className={cn(styles.checkbox, indent, className)}>
      <input
        checked={value}
        disabled={disabled}
        type="checkbox"
        onChange={onChange && ((e) => onChange(e.target.checked))}
        {...inputProps}
      />
    </label>
  );
}
