import { useState } from "react";
import { Notification } from "services/notification";
import type { ErrorResponse } from "types/commons";
import { ETranslations } from "types/translates";

import { DEFAULT_DELAY } from "../../../constants";
import { coreApi } from "./core";

const printBookingApi = coreApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    printBookingInfo: build.mutation<void, { bookingId: number | null }>({
      query: (body) => ({
        url: "v2/print",
        method: "post",
        body,
      }),
      async onQueryStarted(_params, { queryFulfilled }) {
        try {
          Notification.info({ title: ETranslations.PRINTING_STARTED });
          await queryFulfilled.then(() =>
            Notification.info({ title: ETranslations.PRINTING }),
          );
        } catch (e) {
          const message = (e as ErrorResponse).error?.data?.errorMessage;
          Notification.error({ title: ETranslations.PRINTING_FAILED, message });
        }
      },
    }),
  }),
});

const { usePrintBookingInfoMutation } = printBookingApi;

export function usePrintBookingInfo(bookingId: number | null) {
  const printFunc = usePrintBookingInfoMutation()[0];
  const [isDisabled, setIsDisabled] = useState(false);
  const print = () => {
    setIsDisabled(true);
    printFunc({ bookingId })
      .then((data) => {
        return new Promise((resolve) =>
          setTimeout(
            resolve,
            "data" in data ? DEFAULT_DELAY * 4 : DEFAULT_DELAY,
          ),
        );
      })
      .finally(() => setIsDisabled(false));
  };
  return { print, isDisabled };
}
