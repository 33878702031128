import {
  isPageDashboard,
  isPageGuests,
  isPageHall,
  isPageRequests,
  isPageSchedule,
  isPageSettings,
} from "common/helpers";
import { PlaceSelect } from "components/PlaceSelect";
import { NotesButton } from "components/RestaurantNotes";
import { RestaurantSelect } from "components/RestaurantSelect";
import { BookingsReport } from "components/modals/BookingsReport";
import { ReportPlacement } from "components/modals/PlacementReport";
import { config } from "config";
import { DIALOG_TABS, TAB_KEY } from "containers/Dialogs/constans";
import {
  dateSelector,
  placesOptionsSelector,
  restaurantSelector,
  selectedPlacesSelector,
} from "features/AppContext";
import { useApplicationContextActions } from "features/AppContext/slice";
import { useFromProxyActions } from "features/BookingFormProxy";
import * as select from "features/BookingFormProxy/selectors";
import { useHallSchemaActions } from "features/HallSchema";
import { hallModeSelector } from "features/HallSchema/selectors";
import { useTableBookingListActions } from "features/TableBooking/slice";
import _ from "lodash";
import { SingleOption } from "models/common";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCheckPermission } from "services/permissionChecker";
import type { Params } from "types/commons";
import { Button, Header, ICONS, LinkButton, SelectCheckbox } from "ui-kit";

import { HALLS_TITLES } from "../../../constants";
import { useIntlUtils } from "../../../hooks/useIntlUtils";
import { ETranslations } from "../../../types/translates";
import { Telephony } from "../../telephony/telephony";
import { DateControls } from "./DateControls";
import styles from "./Header.module.scss";
import { SearchHeader } from "./SearchHeader";

type HeaderProps = {
  isDialogsBooking: boolean;
};

enum ReportModal {
  Booking = "Booking",
  Placement = "Placement",
}

const DialogsBookingHeader = ({
  label,
  search,
}: {
  label: string;
  search: string;
}) => {
  const navigate = useNavigate();
  const { dialogId } = useParams<Params>();
  const searchParams = search.includes(DIALOG_TABS.CHAT)
    ? search
    : `?${TAB_KEY}=${DIALOG_TABS.FEEDS}`;

  return (
    <>
      <Header.Part placement="left" className={styles.dialogsReservation}>
        {label}
      </Header.Part>
      <Header.Part placement="right">
        <Button
          variant="phantom"
          onClick={() =>
            navigate({ pathname: `/dialogs/${dialogId}`, search: searchParams })
          }
          className={styles.closeButton}
        >
          <ICONS.Cross />
        </Button>
      </Header.Part>
    </>
  );
};

export const AppHeader = ({ isDialogsBooking }: HeaderProps) => {
  const { getIntlChooseEntity, intl } = useIntlUtils();
  const { hasPermissionFor } = useCheckPermission();

  const { setRestaurant, setSelectedPlaces } = useApplicationContextActions();
  const { reset } = useTableBookingListActions();
  const { setActiveTables } = useHallSchemaActions();
  const { reset: resetBooking } = useFromProxyActions();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const resetTablesAndNavigate = () => {
    setActiveTables({ activeTables: [] });
    reset();
    resetBooking();
    navigate("/create-booking", { state: { from: pathname } });
  };

  const [reportModal, setReportModal] = useState<ReportModal | undefined>();
  const closeModal = useCallback(() => setReportModal(undefined), []);
  const placesOptions = useSelector(placesOptionsSelector);
  const selectedPlaces = useSelector(selectedPlacesSelector);
  const hallMode = useSelector(hallModeSelector);
  const isShowPrint = Boolean(hallMode);
  const isEditMode = useSelector(select.isEdit);

  const isMultiplePlaces = () =>
    (isPageDashboard() || isPageSettings()) && !isEditMode;

  const getIcon = () => {
    if (isPageDashboard() || isPageSchedule()) {
      return (
        <img
          alt="reportModalIcon"
          src={reportModal ? ICONS.reportActive : ICONS.reportDeactive}
        />
      );
    }
    return <ICONS.Print />;
  };

  const mapPlacesById = (nextPlaces: any[] = []) => {
    const idArray = _.uniq(nextPlaces).map((it) => it.value);
    setSelectedPlaces(idArray);
  };

  const selectedPlacesOptions = useMemo(
    () =>
      selectedPlaces
        .map(
          (it) =>
            placesOptions.find((p) => p.value === it) ||
            new SingleOption(NaN, ""),
        )
        .filter((it) => !Number.isNaN(it.value)) || [],
    [placesOptions, selectedPlaces],
  );

  const restaurant = useSelector(restaurantSelector);

  const isSearchPage = useMemo(() => pathname === "/search", [pathname]);

  const isDialogs = /^\/dialogs\/?/.test(pathname);

  const content = useMemo(() => {
    if (isSearchPage) return <SearchHeader />;
    return (
      <>
        {
          <Header.Part className={styles.restaurantControls} placement="left">
            <RestaurantSelect
              value={restaurant.restaurant_id}
              onRestaurantChange={setRestaurant}
            />
            {!isPageSchedule() &&
              (isMultiplePlaces() ? (
                <SelectCheckbox
                  isClearable={false}
                  options={placesOptions}
                  placeholder={getIntlChooseEntity(
                    ETranslations.PLURAL_HALLS_NOM,
                  )}
                  titles={HALLS_TITLES}
                  value={selectedPlacesOptions}
                  // @ts-ignore
                  onChange={mapPlacesById}
                />
              ) : (
                <PlaceSelect />
              ))}
          </Header.Part>
        }
        <Header.Part placement="right">
          {!isPageRequests() && !isPageGuests() && !isPageSettings() && (
            <DateControls />
          )}
          <Telephony />
          {config.isReportEnabled &&
            Boolean(
              isPageDashboard() ||
                isPageSchedule() ||
                (isPageHall() && isShowPrint),
            ) &&
            hasPermissionFor("FEATURE_BOOKING_REPORT") && (
              <Button
                className={styles.printBtn}
                variant="phantom"
                square
                onClick={(e) => {
                  e.stopPropagation();
                  if (isPageHall()) {
                    const currentTheme = document.documentElement.dataset.mode;
                    document.documentElement.dataset.mode = "light";
                    window.print();
                    document.documentElement.dataset.mode = currentTheme;
                  } else if (isPageDashboard()) {
                    setReportModal(ReportModal.Booking);
                  } else {
                    setReportModal(ReportModal.Placement);
                  }
                }}
              >
                <ICONS.Print height={31} width={31} />
                <div className="tooltip">
                  {intl.formatMessage({
                    id: isPageHall()
                      ? ETranslations.PRINT
                      : ETranslations.UPLOAD,
                  })}
                </div>
              </Button>
            )}
          {hasPermissionFor("ACCESS_BOOKING_CREATE") && (
            <Button variant="primary" square onClick={resetTablesAndNavigate}>
              <ICONS.Plus />
            </Button>
          )}
          {/* FIXME: Убрать, как внедрим новую форму */}
          {hasPermissionFor("ACCESS_GUEST_NOTES_GLOBAL_DELETE") && (
            <LinkButton
              to="/create-booking-new"
              className={styles.buttonPlus}
              variant="primary"
              square
            >
              <ICONS.Plus />
            </LinkButton>
          )}
        </Header.Part>
      </>
    );
  }, [
    isSearchPage,
    restaurant,
    setRestaurant,
    placesOptions,
    selectedPlacesOptions,
    isShowPrint,
    setReportModal,
    getIcon,
    history,
  ]);

  return (
    <>
      <Header>
        {isDialogs
          ? isDialogsBooking && (
              <DialogsBookingHeader
                label={intl.formatMessage({
                  id: ETranslations.RESERVATION_FROM_DIALOG,
                })}
                search={search}
              />
            )
          : content}
      </Header>
      <BookingsReport
        closeModal={closeModal}
        isOpen={
          reportModal === ReportModal.Booking &&
          hasPermissionFor("FEATURE_BOOKING_REPORT")
        }
      />
      <ReportPlacement
        closeModal={closeModal}
        isOpen={
          reportModal === ReportModal.Placement &&
          hasPermissionFor("FEATURE_BOOKING_REPORT")
        }
      />
    </>
  );
};

export const DashboardHeader = () => {
  const { getIntlChooseEntity, intl } = useIntlUtils();
  const { hasPermissionFor } = useCheckPermission();

  const { setRestaurant, setSelectedPlaces } = useApplicationContextActions();
  const { reset } = useTableBookingListActions();
  const { setActiveTables } = useHallSchemaActions();
  const { reset: resetBooking } = useFromProxyActions();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const resetTablesAndNavigate = () => {
    setActiveTables({ activeTables: [] });
    reset();
    resetBooking();
    navigate("/create-booking", { state: { from: pathname } });
  };

  const [reportModal, setReportModal] = useState<ReportModal | undefined>();
  const closeModal = useCallback(() => setReportModal(undefined), []);
  const placesOptions = useSelector(placesOptionsSelector);
  const selectedPlaces = useSelector(selectedPlacesSelector);
  const hallMode = useSelector(hallModeSelector);
  const isShowPrint = Boolean(hallMode);
  const isEditMode = useSelector(select.isEdit);
  const date = useSelector(dateSelector);
  const restaurant = useSelector(restaurantSelector);

  const isMultiplePlaces = () =>
    (isPageDashboard() || isPageSettings()) && !isEditMode;

  const getIcon = () => {
    if (isPageDashboard() || isPageSchedule()) {
      return (
        <img
          alt="reportModalIcon"
          src={reportModal ? ICONS.reportActive : ICONS.reportDeactive}
        />
      );
    }
    return <ICONS.Print />;
  };

  const mapPlacesById = (nextPlaces: any[] = []) => {
    const idArray = _.uniq(nextPlaces).map((it) => it.value);
    setSelectedPlaces(idArray);
  };

  const selectedPlacesOptions = useMemo(
    () =>
      selectedPlaces
        .map(
          (it) =>
            placesOptions.find((p) => p.value === it) ||
            new SingleOption(NaN, ""),
        )
        .filter((it) => !Number.isNaN(it.value)) || [],
    [placesOptions, selectedPlaces],
  );

  const content = useMemo(() => {
    return (
      <>
        {
          <Header.Part className={styles.restaurantControls} placement="left">
            <RestaurantSelect
              value={restaurant.restaurant_id}
              onRestaurantChange={setRestaurant}
            />
            {!isPageSchedule() &&
              (isMultiplePlaces() ? (
                <SelectCheckbox
                  isClearable={false}
                  options={placesOptions}
                  placeholder={getIntlChooseEntity(
                    ETranslations.PLURAL_HALLS_NOM,
                  )}
                  titles={HALLS_TITLES}
                  value={selectedPlacesOptions}
                  // @ts-ignore
                  onChange={mapPlacesById}
                />
              ) : (
                <PlaceSelect />
              ))}
          </Header.Part>
        }
        <Header.Part placement="right">
          <DateControls />
          <NotesButton
            restaurantId={restaurant.restaurant_id}
            date={date.format("YYYY-MM-DD")}
          />
          <Telephony />
          {config.isReportEnabled &&
            hasPermissionFor("FEATURE_BOOKING_REPORT") && (
              <Button
                className={styles.printBtn}
                variant="phantom"
                square
                onClick={(e) => {
                  e.stopPropagation();
                  setReportModal(ReportModal.Booking);
                }}
              >
                <ICONS.Print height={31} width={31} />
                <div className="tooltip">
                  {intl.formatMessage({
                    id: ETranslations.UPLOAD,
                  })}
                </div>
              </Button>
            )}
          {hasPermissionFor("ACCESS_BOOKING_CREATE") && (
            <Button variant="primary" square onClick={resetTablesAndNavigate}>
              <ICONS.Plus />
            </Button>
          )}
          {/* FIXME: Убрать, как внедрим новую форму */}
          {hasPermissionFor("ACCESS_GUEST_NOTES_GLOBAL_DELETE") && (
            <LinkButton
              to="/create-booking-new"
              className={styles.buttonPlus}
              variant="primary"
              square
            >
              <ICONS.Plus />
            </LinkButton>
          )}
        </Header.Part>
      </>
    );
  }, [
    restaurant,
    setRestaurant,
    placesOptions,
    selectedPlacesOptions,
    isShowPrint,
    setReportModal,
    getIcon,
    history,
  ]);

  return (
    <>
      <Header>{content}</Header>

      <BookingsReport
        closeModal={closeModal}
        isOpen={
          reportModal === ReportModal.Booking &&
          hasPermissionFor("FEATURE_BOOKING_REPORT")
        }
      />
      <ReportPlacement
        closeModal={closeModal}
        isOpen={
          reportModal === ReportModal.Placement &&
          hasPermissionFor("FEATURE_BOOKING_REPORT")
        }
      />
    </>
  );
};
