import {
  BookingSource,
  DepositStatus,
} from "containers/CreateBookingForm/model";
import { Tag } from "models/tags.model";
import type { IntlShape } from "react-intl";
import { z } from "zod";

export const getExtraFormSchema = (t: IntlShape["formatMessage"]) =>
  z.object({
    tags: Tag.array(),
    comment: z.string().optional(),
    deposit: DepositStatus,
    source: BookingSource.optional(),
  });

export type ExtraFormSchema = z.infer<ReturnType<typeof getExtraFormSchema>>;
