import { Modal } from "components/modal";
import CurrentRuleForm from "components/shifts/Form/AccessRuleForm/CurrentRuleForm";
import type { AccessRule, AccessRuleGroup, WidgetConfig } from "models/accessRules.model";
import React from "react";



import { BaseShiftsModal } from "../BaseShiftsModal";
import styles from "../style.module.scss";


interface AccessRulesModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  currentRule: AccessRule;
  ruleGroup: AccessRuleGroup & { widget?: WidgetConfig };
}

export const CurrentRuleModal = ({
  isOpen,
  onClose,
  title,
  currentRule,
  ruleGroup,
}: AccessRulesModalProps) => {
  return (
    <BaseShiftsModal isOpen={isOpen} title={title} onClose={onClose}>
      <Modal.Content className={styles.modalContentAccess}>
        <CurrentRuleForm currentRule={currentRule} ruleGroup={ruleGroup} onClose={onClose} />
      </Modal.Content>
    </BaseShiftsModal>
  );
};