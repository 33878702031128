import React from "react";
import { Form, FormSpy } from "react-final-form";
import { Spinner } from "ui-kit";

import useBookingFormReduxProxy from "./hooks/useBookingFormReduxProxy";
import { FormProxyProps } from "./types";

const BookingForm = React.lazy(() => import("./BookingForm"));

function BookingFormReduxProxy<P>({
  component: Component,
  isRegisterVisit,
  isCreating = true,
  isUpdate = false,
  isFromManagement = false,
  booking,
  isAnonym,
  clientData,
  openGuestList,
  initialFormValues,
  onNavigateFromDialog,
  ...rest
}: FormProxyProps<P>) {
  const { onSubmit, initial, dispatchEventFn } = useBookingFormReduxProxy({
    isRegisterVisit,
    isCreating,
    booking,
    isAnonym,
    clientData,
    initialFormValues,
    ...rest,
  });
  return (
    <Form
      initialValues={initial}
      key={booking?.bookingId || booking}
      render={(props) => (
        <>
          {Component ? (
            <Component {...props} {...rest} />
          ) : (
            <React.Suspense fallback={<Spinner />}>
              <BookingForm
                booking={booking}
                isAnonym={isAnonym}
                isFromManagement={isFromManagement}
                isRegisterVisit={isRegisterVisit}
                openGuestList={openGuestList}
                isUpdate={isUpdate}
                isFromDialog={Boolean(clientData)}
                onNavigateFromDialog={onNavigateFromDialog}
              />
            </React.Suspense>
          )}
          <FormSpy
            subscription={{ values: true }}
            onChange={(form) => dispatchEventFn(form.values)}
          />
        </>
      )}
      onSubmit={onSubmit}
    />
  );
}

export default React.memo(BookingFormReduxProxy);
